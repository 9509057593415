import React from "react"

const ClientEnquiry = () => {
    return (
        <>
            {/*Main Container*/}
            <div
                style={{
                    margin: "0",
                    padding: "0",
                    background: "#ECEDFE",
                }}
            >
                {/*Email Container*/}
                <div
                    style={{
                        // background: '#fff',
                        margin: "0 auto",
                        padding: "50px 0",
                        color: "#514d6a",
                        borderRadius: "5px",
                        maxWidth: "550px",
                        fontSize: "14px",
                        textAlign: "center",
                    }}
                >
                    {/*Company Name / Logo*/}
                    <div
                        style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                            padding: "10px",
                            marginBottom: "20px",
                        }}
                    >
                        <a href={"_DOMAIN_NAME_"}>
                            <img
                                src={
                                    // eslint-disable-next-line max-len
                                    "https://firebasestorage.googleapis.com/v0/b/progresspicture.appspot.com/o/progress_picture_logo_no_bg_cropped.png?alt=media"
                                }
                                alt="_COMPANY_NAME_"
                                style={{ width: "240px", maxWidth: "100%" }}
                            />
                        </a>
                    </div>

                    {/*CARD*/}
                    <div
                        style={{
                            padding: "40px 40px 20px 40px",
                            background: "#fff",
                            borderRadius: "20px",
                            borderStyle: "none",
                            borderWidth: "1px",
                            justifyContent: "center",
                        }}
                    >
                        {/*Subject*/}
                        <div>
                            <h4
                                style={{
                                    marginBottom: "30px",
                                    color: "#0d0c46",
                                    fontWeight: "600",
                                    fontSize: "24px",
                                }}
                            >
                                Client Enquiry
                            </h4>
                        </div>

                        {/*Email Body*/}
                        <div style={{ margin: "30px 0", fontSize: "18px", fontWeight: 400 }}>
                            <p>Hello _TRAINER_NAME_,</p>
                            <span>
                                _CLIENT_NAME_ has sent a message to you from ProgressPicture - see below for responses.
                                <br />
                                <br />
                                <div style={{ verticalAlign: "middle", textAlign: "start" }}>
                                    <span>Name: _CLIENT_NAME_</span>
                                    <br />
                                    <span>Email Address: _CLIENT_EMAIL_</span>
                                    <br />
                                    <span>Phone No: _CLIENT_PHONE_</span>
                                    <br />
                                    <span>Service Name: _SERVICE_NAME_</span>
                                    <br />
                                    <span>Gender: _CLIENT_GENDER_</span>
                                    <br />
                                    <span>Age: _CLIENT_AGE_</span>
                                    <br />
                                    <span>Weight: _CLIENT_WEIGHT_</span>
                                    <br />
                                    <span>What are your goals?</span>
                                    <br />
                                    <span>_CLIENT_GOAL_</span>
                                    <br />
                                </div>
                                <br />
                                <br />
                                <div style={{ verticalAlign: "middle", textAlign: "center" }}>
                                    <span>Thanks,</span>
                                    <br />
                                    <span>Team ProgressPicture</span>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClientEnquiry
