export const getPostPrivacyText = (postPrivacy) =>
    `${
        postPrivacy === "PRIVATE"
            ? "Only I"
            : postPrivacy === "RESTRICTED"
            ? "My approved followers/friends and I"
            : "Everyone"
    } can view my transformation`

export const dobDateFormat = "DD-MM-YYYY"

export const progressPictureDateFormat = "DD-MM-YYYY"
