import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Badge, Button, Dropdown, Empty, Menu } from "antd"
import { BellOutlined } from "@ant-design/icons"
import moment from "moment"
import { userActions } from "reduxStore"
import { selectNotifications, selectUsername } from "reduxStore/selectors"
import { handleFollowRequestDecision, markNotificationAsRead, markNotificationsAsViewed } from "services/firebase"
import { updateNotificationInfo } from "utils"
import "../styles.scss"

const Notifications = () => {
    const username = useSelector(selectUsername)
    const userNotifications = useSelector(selectNotifications)
    const dispatch = useDispatch()
    const history = useHistory()
    const [notifications, setNotifications] = useState([])
    // const [unreadNotifications, setUnreadNotifications] = useState([])
    const [notViewedNotifications, setNotViewedNotifications] = useState([])
    const [processingDecision, setProcessingDecision] = useState(false)

    let markNotificationsViewedTimer
    const markAsViewedTimeThreshold = 3000

    useEffect(() => {
        let updatedNotifications = userNotifications.map((notification) => updateNotificationInfo(notification))
        // eslint-disable-next-line max-len
        // const unreadNotifications = updatedNotifications.filter(notification => !notification.read.includes(username))
        const notViewedNotifications = updatedNotifications.filter(
            (notification) => !notification.viewed.includes(username)
        )
        setNotifications(updatedNotifications)
        // setUnreadNotifications(unreadNotifications)
        setNotViewedNotifications(notViewedNotifications)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userNotifications])

    const handleNotificationClick = (notification) => {
        clearTimeout(markNotificationsViewedTimer)
        if (notification.link) {
            history.push(notification.link)
        }
        markNotificationAsRead(notification.notificationId, username)
            .then(() => dispatch(userActions.notificationRead({ notificationId: notification.notificationId })))
            .catch(console.error)
    }

    const viewNotifications = () => {
        console.log(`${notViewedNotifications.length} notifications viewed!`)
        const notificationsIdsToView = notViewedNotifications.map((notification) => notification.notificationId)
        markNotificationsAsViewed(username, notificationsIdsToView)
            .then(() => dispatch(userActions.notificationsViewed({ viewedNotificationIds: notificationsIdsToView })))
            .catch(console.error)
    }

    const _handleFollowRequestDecision = (notification, decision) => {
        setProcessingDecision(true)
        handleFollowRequestDecision(notification.sender, username, decision)
            .then(() => {
                dispatch(userActions.removeNotification({ notificationId: notification.notificationId }))
            })
            .catch(console.error)
            .finally(() => setProcessingDecision(false))
    }

    return (
        <div className="notifications">
            <Dropdown
                onVisibleChange={(value) => {
                    clearTimeout(markNotificationsViewedTimer)
                    if (value) markNotificationsViewedTimer = setTimeout(viewNotifications, markAsViewedTimeThreshold)
                }}
                overlay={
                    <Menu className="navbar-dropdown">
                        {notifications.length > 0 ? (
                            notifications.map((notification, index) => (
                                <Menu.Item
                                    className={`Notification ${
                                        notification.read.includes(username) ? "read" : "unread"
                                    }`}
                                    key={index}
                                    onClick={() => handleNotificationClick(notification)}
                                >
                                    <div className="avatar">{notification.icon}</div>
                                    <div>
                                        {notification.type === "followRequest" ? (
                                            <>
                                                <div>
                                                    <b>{notification.sender}</b> has requested to follow your account
                                                </div>
                                                <div className="action-buttons">
                                                    <Button
                                                        size="small"
                                                        type="primary"
                                                        value={"approve"}
                                                        loading={processingDecision}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            _handleFollowRequestDecision(
                                                                notification,
                                                                e.currentTarget.value
                                                            )
                                                        }}
                                                    >
                                                        Approve
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        type="primary"
                                                        danger
                                                        value={"deny"}
                                                        loading={processingDecision}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            _handleFollowRequestDecision(
                                                                notification,
                                                                e.currentTarget.value
                                                            )
                                                        }}
                                                    >
                                                        Deny
                                                    </Button>
                                                </div>
                                                <div className="text-small mid-blue">
                                                    {moment(notification.createdOn).fromNow()}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div>{notification.message}</div>
                                                <div className="text-small mid-blue">
                                                    {moment(notification.createdOn).fromNow()}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Menu.Item>
                            ))
                        ) : (
                            <Menu.Item key="default">
                                <div className="no-notifications">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No notifications yet" />
                                </div>
                            </Menu.Item>
                        )}
                    </Menu>
                }
                trigger={["click"]}
                placement="bottomRight"
                arrow
            >
                <Badge count={notViewedNotifications.length} offset={[-7, 7]}>
                    <Button type="link" size="large" icon={<BellOutlined />} />
                </Badge>
            </Dropdown>
        </div>
    )
}

export default Notifications
