import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { NavLink } from "react-router-dom"
import { Button, Dropdown, Menu } from "antd"
import { DownOutlined, MenuOutlined, SearchOutlined } from "@ant-design/icons"
import { insertIf, toTitleCase } from "@progresspicture/shared/utils"
import { UnitPreferences } from "@progresspicture/shared/validation"
import Notifications from "components/ui/Navbar/Notifications"
import { authActions, userActions } from "reduxStore"
import {
    selectAccountType,
    selectDisplayName,
    selectHasPersonalTransformation,
    selectIsLoggedIn,
    selectIsUserLoading,
    selectUnitPreference,
    selectUsername,
} from "reduxStore/selectors"
import { showModal } from "utils"
import NavbarSearch from "../NavbarSearch"
import logoMobile from "assets/images/icon.svg"
import logoDesktop from "assets/images/logo.svg"
import "./styles.scss"

const Navbar = ({ clear }) => {
    const hasTransformation = useSelector(selectHasPersonalTransformation)
    const loggedIn = useSelector(selectIsLoggedIn)
    const displayName = useSelector(selectDisplayName)
    const unitPreference = useSelector(selectUnitPreference)
    const loading = useSelector(selectIsUserLoading)
    const accountType = useSelector(selectAccountType)
    const userName = useSelector(selectUsername)
    const history = useHistory()
    const dispatch = useDispatch()

    const [showSearch, setShowSearch] = useState(false)

    const navItemsLeft = [
        {
            label: "Transformations",
            url: "/transformations",
            button: false,
            onClick: undefined,
        },
        {
            label: "Coaches",
            url: "/coaches",
            button: false,
            onClick: undefined,
        },
        {
            label: "For businesses",
            url: "/business",
            button: false,
            onClick: undefined,
        },
    ]

    const loggedOutNavItems = [
        {
            label: "Login",
            // url: "",
            button: true,
            onClick: () => showModal("auth", "login"),
        },
        {
            label: "Add transformation",
            // url: "/user/submit",
            // search: "?authAction=login&onSuccess=/user/submit",
            button: "primary",
            onClick: () => history.push("/user/submit"),
        },
    ]

    const loggedInNavItems = [
        ...insertIf(!hasTransformation && accountType === "individual", [
            {
                label: "Add Transformation",
                url: "/user/submit",
                button: false,
                onClick: undefined,
            },
        ]),
        ...insertIf(hasTransformation && accountType === "individual", [
            {
                label: "Add Progress Update",
                url: "/user/dashboard#progress_updates",
                button: false,
                onClick: undefined,
            },
        ]),
        ...insertIf(accountType === "trainer", [
            {
                label: "Add Client Transformation",
                url: "/submit/client",
                button: false,
                onClick: undefined,
            },
        ]),
        {
            label: "Profile",
            url: accountType === "trainer" ? `/coach/${userName}` : `/profile/user/${userName}`,
            button: false,
            onClick: undefined,
        },
        {
            label: "Dashboard",
            url: "/user/dashboard",
            button: false,
            onClick: undefined,
        },
        {
            label: "Logout",
            url: undefined,
            button: false,
            onClick: () => dispatch(authActions.logout()),
        },
    ]

    const mobileDropdownNavItems = !loggedIn
        ? [...navItemsLeft, ...loggedOutNavItems]
        : [...navItemsLeft, ...loggedInNavItems]

    const desktopNavItemsRight = !loggedIn ? loggedOutNavItems : []

    const handleUnitClick = (unit) => {
        if (unit !== unitPreference.unit) {
            dispatch(userActions.toggleUnitPreference())
        }
    }

    return (
        <div className={`Navbar ${clear ? "clear" : ""}`}>
            <div className="container">
                <div className="content">
                    <NavLink to="/">
                        <img className="logo desktop" src={logoDesktop} alt="ProgressPicture" />
                        <img className="logo mobile" src={logoMobile} alt="ProgressPicture" />
                    </NavLink>
                    {showSearch ? (
                        <NavbarSearch setShowSearch={setShowSearch} />
                    ) : (
                        <>
                            <div className="left">
                                <div className="links">
                                    {navItemsLeft.map((item) => (
                                        <div className="link" key={item.label}>
                                            <NavLink
                                                to={{
                                                    pathname: item.url,
                                                    search: item.search || "",
                                                }}
                                            >
                                                <Button
                                                    type={!item.button ? "text" : "primary"}
                                                    danger={item.danger}
                                                    size="large"
                                                    onClick={item.onClick}
                                                >
                                                    {item.label}
                                                </Button>
                                            </NavLink>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="right">
                                <Dropdown
                                    overlay={
                                        <Menu className="navbar-dropdown">
                                            {Object.values(UnitPreferences).map((unitObj, index) => (
                                                <Menu.Item key={index} onClick={() => handleUnitClick(unitObj.unit)}>
                                                    <strong>{toTitleCase(unitObj.unit)}</strong>{" "}
                                                    <span className="mid-blue">
                                                        {/* eslint-disable-next-line max-len */}
                                                        {unitObj.height} <span className="text-faint"> &middot; </span>{" "}
                                                        {unitObj.weight}
                                                    </span>
                                                </Menu.Item>
                                            ))}
                                        </Menu>
                                    }
                                    trigger={["click"]}
                                    placement="bottomRight"
                                    arrow
                                >
                                    <Button shape="circle" type="text" size="large">
                                        {unitPreference.height}
                                        <span className="text-faint">&middot;</span>
                                        {unitPreference.weight}
                                    </Button>
                                </Dropdown>

                                <Button shape="circle" type="text" size="large" onClick={() => setShowSearch(true)}>
                                    <SearchOutlined />
                                </Button>
                                <div className="links">
                                    {desktopNavItemsRight.map((item) => (
                                        <div className="link" key={item.label}>
                                            <Button
                                                type={
                                                    !item.button ? "link" : item.button === "primary" ? "primary" : null
                                                }
                                                danger={item.danger}
                                                size="large"
                                                onClick={item.onClick}
                                            >
                                                {item.label}
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                                {loggedIn && (
                                    <>
                                        <Notifications />
                                        <div className="dropdown-desktop">
                                            <Dropdown
                                                overlay={
                                                    <Menu className="navbar-dropdown">
                                                        {loggedInNavItems.map((item, index) => (
                                                            <React.Fragment key={index}>
                                                                <Menu.Item
                                                                    data-se="navbar-dropdown-menu-item"
                                                                    key={item.label}
                                                                    onClick={item.onClick}
                                                                >
                                                                    {item.onClick ? (
                                                                        item.label
                                                                    ) : (
                                                                        <Link to={item.url}>{item.label}</Link>
                                                                    )}
                                                                </Menu.Item>
                                                                {index === loggedInNavItems.length - 2 && (
                                                                    <Menu.Divider />
                                                                )}
                                                            </React.Fragment>
                                                        ))}
                                                    </Menu>
                                                }
                                                trigger={["click"]}
                                                placement="bottomRight"
                                                arrow
                                            >
                                                <Button size="large" type="primary" loading={loading}>
                                                    {displayName}
                                                    <DownOutlined data-se="navbar-dropdown-icon" className="icon" />
                                                </Button>
                                            </Dropdown>
                                        </div>
                                    </>
                                )}

                                <div className="dropdown-mobile">
                                    <Dropdown
                                        overlay={
                                            <Menu className="navbar-dropdown">
                                                {mobileDropdownNavItems.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        <Menu.Item key={item.label} onClick={item.onClick}>
                                                            {item.onClick ? (
                                                                item.label
                                                            ) : (
                                                                <Link to={item.url}>{item.label}</Link>
                                                            )}
                                                        </Menu.Item>
                                                    </React.Fragment>
                                                ))}
                                            </Menu>
                                        }
                                        trigger={["click"]}
                                        placement="bottomRight"
                                        arrow
                                    >
                                        <Button shape="circle" type="primary" size="large" icon={<MenuOutlined />} />
                                    </Dropdown>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Navbar
