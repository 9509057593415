import React from "react"
import { Link } from "react-router-dom"
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai"
import { Divider } from "antd"
import FooterCtaButton from "../Buttons/footerCta"
import logoDesktop from "assets/images/logo.svg"
import "./styles.scss"

const Footer = () => {
    return (
        <div className="Footer">
            <div className="container">
                <div className="grid">
                    <div className="info">
                        <Link to="/">
                            <img className="logo" src={logoDesktop} alt="logo" />
                        </Link>
                        <p className="text-small mid-blue">A better way to do progress pictures.</p>
                        <div className="social">
                            <a href="https://www.instagram.com/progress.picture/" target="_blank">
                                <AiFillInstagram size={24} />
                            </a>
                            <a href="https://www.facebook.com/useprogresspicture" target="_blank">
                                <AiFillFacebook size={24} />
                            </a>
                            {/*<a>*/}
                            {/*    <AiOutlineTwitter size={24} />*/}
                            {/*</a>*/}
                        </div>
                    </div>
                    <div className="links">
                        <div className="links-section">
                            <h5>
                                <Link to="/transformations">
                                    <strong>Transformations</strong>
                                </Link>
                            </h5>

                            <div className="text-small">
                                <Link to="/transformations?goal=WEIGHT_LOSS">Weight loss</Link>
                            </div>
                            <div className="text-small">
                                <Link to="/transformations?goal=WEIGHT_GAIN">Weight gain</Link>
                            </div>
                            <div className="text-small">
                                <Link to="/transformations?sport=BODYBUILDING">Bodybuilding</Link>
                            </div>
                            <div className="text-small">
                                <Link to="/transformations?diet=KETO">Keto</Link>
                            </div>
                            <div className="text-small">
                                <FooterCtaButton />
                            </div>
                        </div>
                        {/* <div className="links-section">
                            <h5>
                                <Link to="/coaches">
                                    <strong>Personal Trainers</strong>
                                </Link>
                            </h5>
                            <div className="text-small">
                                <Link to="/coaches?tag=1-to-1 coaching">
                                    1-to-1 coaching
                                </Link>
                            </div>
                            <div className="text-small">
                                <Link to="/coaches?tag=Online coaching">
                                    Online coaching
                                </Link>
                            </div>
                            <div className="text-small">
                                <Link to="/coaches?tag=Nutrition advice">
                                    Nutrition advice
                                </Link>
                            </div>
                            <div className="text-small">
                                <Link to="/coaches?available=1">
                                    Available now
                                </Link>
                            </div>
                            <div className="text-small">
                                <Link to="/user/dashboard">
                                    <Button
                                        size="small"

                                        type="primary"
                                    >
                                        Add trainer profile
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        <div className="links-section">
                            <h5>
                                <Link to="/plans">
                                    <strong>Fitness Plans</strong>
                                </Link>
                            </h5>
                            <div className="text-small">
                                <Link to="/plans?tag=Training Programme">
                                    Training Programme
                                </Link>
                            </div>
                            <div className="text-small">
                                <Link to="/plans?tag=Fitness App">
                                    Fitness App
                                </Link>
                            </div>
                            <div className="text-small">
                                <Link to="/plans?tag=Online Workout">
                                    Online Workout
                                </Link>
                            </div>
                            <div className="text-small">
                                <Link to="/plans?tag=Nutrition Plan">
                                    Nutrition Plan
                                </Link>
                            </div>
                            <div className="text-small">
                                <Link to="/user/dashboard">
                                    <Button
                                        size="small"

                                        type="primary"
                                    >
                                        Submit fitness plan
                                    </Button>
                                </Link>
                            </div>
                        </div> */}
                        <div className="links-section">
                            <h5>
                                <strong>Company</strong>
                            </h5>
                            <div className="text-small">
                                <Link to="/contact">Contact</Link>
                            </div>
                            {/*<div className="text-small">*/}
                            {/*    <Link to="/faqs">FAQs</Link>*/}
                            {/*</div>*/}
                            <div className="text-small">
                                <Link to="/legal/terms-of-service">Terms of service</Link>
                            </div>
                            <div className="text-small">
                                <Link to="/legal/privacy-policy">Privacy policy</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <div className="bottom text-small mid-blue">
                    <p>© 2021 ProgressPicture. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}

export default Footer
