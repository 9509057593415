import React from "react"
import { useSelector } from "react-redux"
import { Divider, Modal, Typography } from "antd"
import Signup from "components/auth/signup"
import { selectAuthModalContext, selectCurrentAuthModal, selectShowAuthModal } from "reduxStore/selectors"
import { closeModal, showModal } from "utils"
import "../styles.modal.scss"

const subTitle = `This action requires you to be logged in. Please login to complete this action or,
                if you don't already have an account, signup in just a few seconds!`

const SignupModal = ({ modalName, cancelCallback, showPrivilegedActionWarning }) => {
    const current_modal = useSelector(selectCurrentAuthModal)
    const show_modal = useSelector(selectShowAuthModal)
    const modalContext = useSelector(selectAuthModalContext)
    const { accType: accountType } = modalContext

    return (
        <Modal
            title={"Create your account"}
            className={"light"}
            centered
            visible={show_modal && current_modal === modalName}
            width={400}
            closable={true}
            keyboard={true}
            destroyOnClose={true}
            onCancel={() => {
                closeModal("auth")
                cancelCallback()
            }}
            footer={""}
        >
            <Signup subTitle={showPrivilegedActionWarning ? subTitle : ""} accountType={accountType} />
            <Divider className="m-0" />
            <div className="exception-text">
                Already have an account?
                <Typography.Link className="change-prompt" onClick={() => showModal("auth", "login")}>
                    <strong>Login</strong>
                </Typography.Link>
            </div>
        </Modal>
    )
}

export default SignupModal
