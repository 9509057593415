import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Button } from "antd"
import { selectHasPersonalTransformation, selectIsIndividualAccount } from "reduxStore/selectors"

const FooterCtaButton = ({ type = "primary", size = "small", ...buttonProps }) => {
    const isIndividualAccount = useSelector(selectIsIndividualAccount)
    const hasTransformation = useSelector(selectHasPersonalTransformation)
    if (!isIndividualAccount) return null
    const ctaButtonText = hasTransformation ? "Add progress update" : "Add transformation"
    const ctaButtonLink = hasTransformation ? "/user/dashboard#progress_updates" : "/user/submit"
    return (
        <Link to={ctaButtonLink}>
            <Button type={type} size={size} {...buttonProps}>
                {ctaButtonText}
            </Button>
        </Link>
    )
}

export default FooterCtaButton
