import { all, put, takeEvery } from "redux-saga/effects"
import store from "store"
import { userActions } from "reduxStore"

// Updates the reduxStore state with SET_STATE and also stores the setting to browser storage
export function* UPDATE_SETTING({ payload }) {
    const { setting, value } = payload
    yield store.set(setting, value)
    yield put(
        userActions.setState({
            [setting]: value,
        })
    )
}

// Defines which saga should run upon each action dispatch
export default function* rootSaga() {
    yield all([takeEvery(userActions.updateSetting.type, UPDATE_SETTING)])
}
