import { createSlice } from "@reduxjs/toolkit"
import initialState from "./initialState"

const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        setState: (state, action) => {
            const { payload } = action
            Object.keys(payload).forEach((key) => {
                state[key] = payload[key]
            })
        },
        updateTransformationLikeCount: (state, action) => {
            const publicTransformations = state.transformations
            const { transformationId, voteType } = action.payload
            const transformationToUpdate = publicTransformations[transformationId]
            if (transformationToUpdate) {
                state.transformations[transformationId].likeCount =
                    voteType === "like" ? transformationToUpdate.likeCount + 1 : transformationToUpdate.likeCount - 1
            }
        },
        updateTransformationCommentCount: (state, action) => {
            const publicTransformations = state.transformations
            const { transformationId, newCommentCount } = action.payload
            const transformationToUpdate = publicTransformations[transformationId]
            if (transformationToUpdate) {
                state.transformations[transformationId].commentCount = newCommentCount
            }
        },
        // saga action creators
        updateSetting: () => {},
        getPublicData: () => {},
        getAllPublicTransformations: () => {},
    },
})

export default homeSlice
