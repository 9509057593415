import { selectCurrentUrlPath } from "../selectors"

export const selectIsLoggedIn = (state) => state.user.loggedIn

export const selectIsEmailVerified = (state) => state.user.userData.emailVerified

export const selectUserRole = (state) => state.user.userData.role

export const selectUsername = (state) => state.user.userData.username

export const selectDisplayName = (state) => state.user.userData.displayName

export const selectEmailAddress = (state) => state.user.userData.email

export const selectAccountType = (state) => state.user.userData.accountType

export const selectIsTrainerAccount = (state) => selectAccountType(state) === "trainer"

export const selectIsIndividualAccount = (state) => selectAccountType(state) === "individual"

export const selectIsAuthorized = (state) => state.user.loggedIn && state.user.userData.emailVerified

export const selectFollowingList = (state) => state.user.following

export const selectIsFollowRequested = (username) => (state) =>
    username && state.user.userData.requestedToFollow && state.user.userData.requestedToFollow.includes(username)

export const selectNotifications = (state) => state.user.notifications

export const selectUnitPreference = (state) => state.user.userData.unitPreference

export const selectUserData = (state) => state.user.userData

export const selectHasPersonalTransformation = (state) => !!Object.keys(state.user.transformations).length

export const selectPersonalTransformation = (state) => {
    const transformationId = selectPersonalTransformationId(state)
    return transformationId ? state.user.transformations[transformationId] : null
}

export const selectPersonalTransformationId = (state) =>
    selectHasPersonalTransformation(state) ? Object.keys(state.user.transformations)[0] : null

export const selectOwnTransformations = (state) => state.user.transformations

export const selectAllowAddPersonalTransformation = (state) =>
    selectIsIndividualAccount(state) && !selectHasPersonalTransformation(state)

export const selectInSubmissionFlow = (state) => state.user.inSubmissionFlow

export const selectIsUserLoading = (state) => state.user.loading

export const selectLikedTransformationIds = (state) => state.user.likedTransformations

export const selectIsUserEligibleForFreeTrial = (state) => state.user.isEligibleForFreeTrial || true

export const selectIsOwnProfilePage = (state) => {
    const ownTransformationId = selectPersonalTransformationId(state)
    const currentUrl = selectCurrentUrlPath(state)
    return currentUrl === `/transformation/${ownTransformationId}`
}
