import { notification } from "antd"
import { all, call, put, select, takeEvery } from "redux-saga/effects"
import store from "store"
import { history } from "index"
import { userActions } from "reduxStore"
import { authActions } from "reduxStore"
import initialAuthState from "reduxStore/auth/initialState"
import modalActions from "reduxStore/modal/actions"
import initialUserState from "reduxStore/user/initialState"
import * as apiAuthServices from "services/api"
import { firebase, firebaseAuth, firebaseAuthServices } from "services/firebase"
import { closeModal, showModal } from "utils"

export function* CONTINUE_WITH_GOOGLE({ payload }) {
    yield put(userActions.setState({ loading: true }))
    const { googleIdToken, accountType, email } = payload
    const location = yield call(apiAuthServices.getTrackingData)
    try {
        const token = yield call(apiAuthServices.continueWithGoogle, email, googleIdToken, accountType, location)
        store.set("authToken", token)
        store.set("authProvider", "google")
        firebaseAuth
            .signInWithCredential(firebase.auth.GoogleAuthProvider.credential(googleIdToken))
            .then((userCredential) => console.log({ userCredential }))
        console.log("CONTINUE WITH GOOGLE SUCCESS!")
        closeModal("auth")
        yield put(authActions.getUserDataApi())
        notification.success({
            message: "Logged In",
            description: "You have successfully logged in!",
        })
    } catch (error) {
        yield put(userActions.setState({ loading: false }))
        notification.error({
            message: "Login failed",
            description: error,
            duration: 10,
        })
    }
}

export function* LOGIN({ payload }) {
    yield put(userActions.setState({ loading: true }))
    // required payload
    const { email, password } = payload
    const { user, error } = yield call(firebaseAuthServices.login, { email, password })
    if (user) {
        apiAuthServices.setupEmailPasswordTokenRefresh(user)
        yield put(authActions.setState({ currentUser: user }))
        closeModal("auth")
        yield put(authActions.getUserData())
        notification.success({
            message: "Logged In",
            description: "You have successfully logged in!",
        })
    } else {
        notification.error({
            message: "Login failed",
            description: error,
            duration: 10,
        })
        yield put(userActions.setState({ loading: false }))
    }
}

export function* SIGNUP({ payload }) {
    yield put(userActions.setState({ loading: true }))

    const {
        email,
        password,
        username,
        displayName,
        businessName = "",
        emailSubscription,
        location,
        accountType,
        inviteId = null,
    } = payload
    // call auth service
    const { user, error } = yield call(
        firebaseAuthServices.createUserAccount,
        {
            email,
            password,
            username,
            displayName,
            businessName,
            emailSubscription,
            accountType,
            inviteId,
        },
        location
    )
    if (user) {
        apiAuthServices.setupEmailPasswordTokenRefresh(user)
        yield put(authActions.setState({ currentUser: user }))
        closeModal("auth")
        yield put(authActions.getUserData())
        notification.success({
            message: "Account registered",
            description: "Please complete email verification to complete account setup",
        })
    } else {
        notification.error({
            message: "Registration failed",
            description: error,
        })
    }
    yield put(userActions.setState({ loading: false }))
}

export function* LOGOUT() {
    yield put(userActions.setState({ loading: true }))
    yield call(firebaseAuthServices.logout)
    history.push("/")
    yield put(authActions.setState({ ...initialAuthState }))
    yield put(userActions.setState({ ...initialUserState }))
    notification.success({ message: "Logged out successfully" })
    yield put(userActions.setState({ loading: false }))
}

export function* GET_USER_DATA() {
    yield put(userActions.setState({ loading: true }))
    const response = yield call(firebaseAuthServices.getUserData)
    if (response) {
        const { currentUser, data } = response
        const {
            userData,
            transformations = {},
            likedTransformations = [],
            notifications = [],
            // followers = [],
            following = [],
        } = data
        yield put(
            userActions.setState({
                userData,
                transformations,
                likedTransformations,
                notifications,
                // followers,
                following,
                loggedIn: true,
            })
        )

        yield put(authActions.setState({ currentUser }))
        if (userData.requireAccountDetails) showModal("auth", "confirmation-required")
        const { authAction, redirect } = yield select((state) => state.auth)
        if (redirect) {
            console.log(`Auth Success : emailVerified=${userData.emailVerified} : REDIRECTING TO -> ${redirect}`)
            history.push(redirect)
            yield put(authActions.setState({ redirect: "", authAction: "" }))
        }
    } else console.log("UNABLE TO LOAD CURRENT USER")

    yield put(userActions.setState({ loading: false }))
}

export function* GET_USER_DATA_API() {
    yield put(userActions.setState({ loading: true }))
    try {
        const data = yield call(apiAuthServices.getUserData)
        const {
            userData,
            transformations = {},
            likedTransformations = [],
            notifications = [],
            followers = [],
            following = [],
        } = data
        yield put(
            userActions.setState({
                userData,
                transformations,
                likedTransformations,
                notifications,
                followers,
                following,
                loggedIn: true,
            })
        )

        if (userData.requireAccountDetails) showModal("auth", "confirmation-required")

        const { authAction, redirect } = yield select((state) => state.auth)
        if (redirect) {
            console.log(`Auth Success : emailVerified=${userData.emailVerified} : REDIRECTING TO -> ${redirect}`)
            history.push(redirect)
            yield put(authActions.setState({ redirect: "", authAction: "" }))
        }
    } catch (error) {
        store.remove("authToken")
        store.remove("authProvider")
        console.debug("UNABLE TO LOAD CURRENT USER")
    }

    yield put(userActions.setState({ loading: false }))
}

export function* RESEND_VERIFICATION_EMAIL() {
    yield put(authActions.setState({ loading: true }))
    try {
        yield call(apiAuthServices.resendVerificationEmail)
        notification.success({
            message: "Verification email sent",
            description: "Please check your email for a verification link",
            duration: 10,
        })
    } catch (errorMsg) {
        notification.error({
            message: `Failed to resend verification email`,
            description: errorMsg,
            duration: 10,
        })
    }
    yield put(authActions.setState({ loading: false }))
}

export function* RESET_PASSWORD({ payload }) {
    yield put(authActions.setState({ loading: true }))
    const { newPassword } = payload
    try {
        store.remove("resetToken")
        const successMsg = yield call(apiAuthServices.resetPassword, newPassword)
        history.push("/")
        yield put({
            type: modalActions.showModal,
            payload: { modalType: "auth", modalName: "login" },
        })
        notification.success({ message: successMsg })
    } catch (error) {
        notification.error({
            message: `Failed to reset password`,
            description: error,
        })
    }
    yield put(authActions.setState({ loading: false }))
}

// Defines which saga should run upon each action dispatch
export default function* rootSaga() {
    yield all([
        takeEvery(authActions.continueWithGoogle.type, CONTINUE_WITH_GOOGLE),
        takeEvery(authActions.login.type, LOGIN),
        takeEvery(authActions.signup.type, SIGNUP),
        takeEvery(authActions.logout.type, LOGOUT),
        takeEvery(authActions.getUserData.type, GET_USER_DATA),
        takeEvery(authActions.getUserDataApi.type, GET_USER_DATA_API),
        takeEvery(authActions.resendVerificationEmail.type, RESEND_VERIFICATION_EMAIL),
        takeEvery(authActions.resetPassword.type, RESET_PASSWORD),
        GET_USER_DATA(), // run once on app load to check user auth
    ])
}
