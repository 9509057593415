import isEmpty from "lodash/isEmpty"
import { all, put, takeEvery } from "redux-saga/effects"
import { insertIf } from "@progresspicture/shared/utils"
import { authActions, userActions } from "reduxStore"
import authSlice from "reduxStore/auth"
import userSlice from "reduxStore/user"
import modalActions from "./actions"

const getModalAction = (modalType) => {
    let actionType
    if (modalType === authSlice.name) actionType = authActions.setState
    if (modalType === userSlice.name) actionType = userActions.setState
    return actionType
}

export function* SHOW_MODAL({ payload }) {
    const { modalType, modalName, modalContext } = payload
    const actionType = getModalAction(modalType)
    if (actionType) {
        yield put(
            actionType({
                show_modal: true,
                current_modal: modalName,
                ...insertIf(!isEmpty(modalContext), { modalContext }),
            })
        )
    }
}

export function* CLOSE_MODAL({ payload }) {
    const { modalType } = payload
    const actionType = getModalAction(modalType)
    yield put(
        actionType({
            show_modal: false,
            current_modal: "",
            modalContext: {},
        })
    )
}

// Defines which saga should run upon each action dispatch
export default function* rootSaga() {
    yield all([takeEvery(modalActions.showModal, SHOW_MODAL), takeEvery(modalActions.closeModal, CLOSE_MODAL)])
}
