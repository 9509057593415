import { createSlice } from "@reduxjs/toolkit"
import initialState from "./initialState"

const configSlice = createSlice({
    name: "config",
    initialState,
    reducers: {
        setState: (state, action) => {
            const { payload } = action
            const { limits, options, constants, defaults } = payload
            state.limits = limits
            state.options = options
            state.constants = constants
            state.defaults = defaults
        },
        // saga action creators
        getConfigData: () => {},
    },
})

export default configSlice
