import { all, call, put, takeEvery } from "redux-saga/effects"
import { configActions } from "reduxStore"
import * as apiServices from "services/api"

export function* GET_CONFIG_DATA() {
    try {
        const config = yield call(apiServices.getConfigData)
        yield put(configActions.setState(config))
    } catch (error) {
        console.error(error)
    }
}

export default function* rootSaga() {
    yield all([takeEvery(configActions.getConfigData.type, GET_CONFIG_DATA), GET_CONFIG_DATA()])
}
