import { message } from "antd"
import axios from "axios"
import qs from "qs"
import store from "store"
import { isEnvDev } from "config"
import { reduxStore } from "index"
import { authActions } from "reduxStore"
import { firebaseAuth } from "./firebase"

const apiBaseUrl = isEnvDev ? process.env.REACT_APP_API_BASE_URL_LOCAL : process.env.REACT_APP_API_BASE_URL

const http = axios.create({
    baseURL: apiBaseUrl,
    timeout: 30 * 1000,
    paramsSerializer: (params) => {
        return qs.stringify(params, {
            encode: false,
        })
    },
})

http.interceptors.request.use(
    (config) => {
        const token = store.get("authToken")
        if (!!token && !config.noAuth) {
            config.headers.common["Authorization"] = "Bearer " + token
            config.headers.common["Provider"] = store.get("authProvider")
        }
        if (!(config.method === "get" || config.data instanceof FormData)) {
            config.headers.common["Content-Type"] = "application/json"
        }
        return config
    },
    (error) => Promise.reject(error)
)

http.interceptors.response.use(
    async (response) => {
        return response.data
    },
    async (errorObj) => {
        const errorStatus = errorObj.response && errorObj.response.status
        const errorData = errorObj.response && errorObj.response.data
        const originalRequest = errorObj.response && errorObj.response.config
        const expectedError = errorObj.response && errorStatus >= 400 && errorStatus < 500
        if (expectedError) {
            if (errorStatus === 401) {
                if (errorData?.error === "ID_TOKEN_REVOKED") {
                    reduxStore.dispatch(authActions.logout())
                    message.error("Logged out for security reasons")
                    return Promise.reject("Auth token revoked - Logged out")
                } else if (errorData?.error === "ID_TOKEN_EXPIRED") {
                    originalRequest._retry = true
                    let newToken = await firebaseAuth.currentUser.getIdToken(true)
                    console.log("refreshed expired token")
                    store.set("authToken", newToken)
                    originalRequest.headers["Authorization"] = "Bearer " + newToken
                    return http(originalRequest)
                } else {
                    reduxStore.dispatch(authActions.logout())
                    message.error("Logged out for security reasons")
                    return Promise.reject("Auth token revoked - Logged out")
                }
            }
        }
        let { error, errors } = errorData
        if (error) {
            console.debug(error)
        }
        if (errors) {
            error = errors[0]
            console.debug("Multiple errors:")
            errors.forEach((err) => console.debug(err))
        }
        return Promise.reject(error)
    }
)

export default http
