import { all, call, put, takeEvery } from "redux-saga/effects"
import store from "store"
import { homeActions } from "reduxStore"
import * as apiServices from "services/api"
import * as firebaseServices from "services/firebase"

// Updates the reduxStore state with SET_STATE and also stores the setting to browser storage
export function* UPDATE_SETTING({ payload }) {
    const { setting, value } = payload
    yield store.set(setting, value)
    yield put(homeActions.setState({ [setting]: value }))
}

export function* GET_PUBLIC_DATA() {
    try {
        const transformationLimits = yield call(apiServices.getPublicData)
        const transformations = yield call(firebaseServices.getAllPublicTransformations)
        yield put(homeActions.setState({ transformationLimits, transformations }))
    } catch (error) {
        console.error(error)
    }
}

export function* GET_ALL_PUBLIC_TRANSFORMATIONS() {
    yield put(homeActions.setState({ loadingPublicTransformations: true }))
    try {
        const transformations = yield call(firebaseServices.getAllPublicTransformations)
        yield put(homeActions.setState({ transformations, loadingPublicTransformations: false }))
    } catch (error) {
        console.error(error)
        yield put(homeActions.setState({ loadingPublicTransformations: false }))
    }
}

// Defines which saga should run upon each action dispatch
export default function* rootSaga() {
    yield all([
        takeEvery(homeActions.updateSetting.type, UPDATE_SETTING),
        takeEvery(homeActions.getPublicData.type, GET_PUBLIC_DATA),
        takeEvery(homeActions.getAllPublicTransformations.type, GET_ALL_PUBLIC_TRANSFORMATIONS),
        GET_PUBLIC_DATA(), // run once on app load to check user auth
    ])
}
