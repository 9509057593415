import { configureStore } from "@reduxjs/toolkit"
import { connectRouter } from "connected-react-router"
import { routerMiddleware } from "connected-react-router"
import { logger } from "redux-logger"
import createSagaMiddleware from "redux-saga"

import { insertIf } from "@progresspicture/shared/utils"
import authSlice from "./auth"
import configSlice from "./config"
import homeSlice from "./home"
import sagas from "./sagas"

import settingsSlice from "./settings"
import userSlice from "./user"

const createReduxStore = (history) => {
    const sagaMiddleware = createSagaMiddleware()
    const routeMiddleware = routerMiddleware(history)
    const middlewares = [sagaMiddleware, routeMiddleware, ...insertIf(process.env.NODE_ENV === "development", [logger])]
    const store = configureStore({
        reducer: {
            router: connectRouter(history),
            [userSlice.name]: userSlice.reducer,
            [authSlice.name]: authSlice.reducer,
            [settingsSlice.name]: settingsSlice.reducer,
            [homeSlice.name]: homeSlice.reducer,
            [configSlice.name]: configSlice.reducer,
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    // Ignore these action types
                    // ignoredActions: [],
                    // Ignore these field paths in all actions
                    ignoredActionPaths: ["payload.currentUser"],
                    // Ignore these paths in the state
                    ignoredPaths: ["auth.currentUser"],
                },
            }).concat(middlewares),
        devTools: { trace: true, traceLimit: 25 },
    })
    sagaMiddleware.run(sagas)
    return store
}

export const userActions = userSlice.actions
export const authActions = authSlice.actions
export const settingsActions = settingsSlice.actions
export const homeActions = homeSlice.actions
export const configActions = configSlice.actions

export { createReduxStore }
