import { all } from "redux-saga/effects"
import auth from "./auth/sagas"
import config from "./config/sagas"
import home from "./home/sagas"
import modal from "./modal/sagas"
import user from "./user/sagas"

export default function* rootSaga() {
    yield all([config(), auth(), modal(), user(), home()])
}
