import { createSlice } from "@reduxjs/toolkit"
import initialState from "./initialState"

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setState: (state, action) => {
            const { payload } = action
            Object.keys(payload).forEach((key) => {
                state[key] = payload[key]
            })
        },
        clearRedirection: (state) => {
            state.redirect = ""
            state.authAction = ""
        },
        // saga action creators
        continueWithGoogle: () => {},
        login: () => {},
        signup: () => {},
        logout: () => {},
        getUserData: () => {},
        getUserDataApi: () => {},
        resendVerificationEmail: () => {},
        resetPassword: () => {},
    },
})

export default authSlice
