import { db } from "./init"

const getUsernameFromUid = async (uid) => {
    let data = await db.collection("users").where("uid", "==", uid).limit(1).get()
    data = data.docs[0]
    if (!data) throw new Error(`Unable to get username for uid = ${uid}`)
    const { username } = data.data()
    return username
}

const queryUserTransformations = async (username) => {
    // returns all the user's personal transformations
    let userTransformations = {}
    let transformations = await db.collection("transformations").where("username", "==", username).get()
    transformations.forEach(
        (doc) =>
            (userTransformations[doc.id] = {
                ...doc.data(),
                transformationId: doc.id,
            })
    )
    return userTransformations
}

const queryLikedTransformations = async (likingUsername) => {
    // returns a list of (liked) transformationIds
    let likedTransformations = []
    let liked = await db.collection("likedTransformations").where("username", "==", likingUsername).get()
    liked.forEach((doc) => likedTransformations.push(doc.data()["transformationId"]))
    return likedTransformations
}

const queryUserNotifications = async (username) => {
    // returns all the notifications for the user, sorted by latest first
    let userNotifications = []
    let notifications = await db
        .collection("notifications")
        .where("recipient", "array-contains", username)
        .orderBy("createdOn", "desc")
        .get()
    notifications.forEach((doc) => userNotifications.push({ ...doc.data(), notificationId: doc.id }))
    return userNotifications
}

const queryClientTransformations = async (username) => {
    // returns all the client transformations for a trainer
    let clientTransformations = {},
        invitesData = {}

    const invitesSnapshot = await db.collection("invites").where("trainer", "==", username).get()
    const clientTransformationsSnapshot = await db
        .collection("clientsTransformation")
        .where("trainer", "==", username)
        .orderBy("createdOn")
        .get()

    invitesSnapshot.docs.forEach((doc) => {
        let data = doc.data()
        invitesData[data.clientTransformationId] = data
    })
    clientTransformationsSnapshot.docs.forEach((doc) => {
        let data = doc.data()
        clientTransformations[data.clientTransformationId] = {
            ...data,
            inviteData: invitesData[data.clientTransformationId] || {},
        }
    })
    return clientTransformations
}

const queryTrainerServices = async (username) => {
    // returns all the Services for a trainer
    let trainerServices = {}
    const trainerServicesSnapshot = await db
        .collection("services")
        .where("trainer", "==", username)
        .orderBy("createdOn")
        .get()
    trainerServicesSnapshot.docs.forEach(
        (doc) =>
            (trainerServices[doc.id] = {
                ...doc.data(),
                serviceId: doc.id,
            })
    )
    return trainerServices
}

const queryPersonalTrainers = async () => {
    let personalTrainers = []
    const personalTrainersSnapshot = await db
        .collection("users")
        .where("accountType", "==", "trainer")
        .orderBy("createdOn")
        .get()
    personalTrainersSnapshot.docs.forEach((doc) => personalTrainers.push(doc.data()))
    return personalTrainers
}

const queryAllClientTransformations = async () => {
    let allClientTransformations = {}
    const allClientTransformationsSnapshot = await db.collection("clientsTransformation").get()
    allClientTransformationsSnapshot.docs.forEach((doc) => (allClientTransformations[doc.id] = doc.data()))
    return allClientTransformations
}

const getTrainerProfileData = async (username) => {
    return (await db.doc(`/users/${username}`).get()).data()
}

const getWidgetData = async (username) => {
    return (await db.doc(`/widgets/${username}`).get()).data()
}

const getUserData = async (username) => {
    return (await db.doc(`/users/${username}`).get()).data()
}

const setWidgetData = async (username, widgetData) => {
    await db.collection("widgets").doc(username).set(widgetData)
    return await getWidgetData(username)
}

export {
    getUsernameFromUid,
    queryUserTransformations,
    queryLikedTransformations,
    queryUserNotifications,
    queryClientTransformations,
    queryAllClientTransformations,
    queryTrainerServices,
    queryPersonalTrainers,
    getTrainerProfileData,
    getWidgetData,
    getUserData,
    setWidgetData,
}
