import React, { Fragment, useEffect, useLayoutEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import qs from "qs"
// import NProgress from "nprogress"
import ConfirmDetailsModal from "modals/confirm-details"
import ForgotPasswordModal from "modals/forgot-password"
import LoginModal from "modals/login"
import SignupModal from "modals/signup"
import VerificationModal from "modals/verification-required"
import { authActions } from "reduxStore"
import {
    selectAccountType,
    selectCurrentAuthModal,
    selectIsLoggedIn,
    selectIsUserLoading,
    selectShowAuthModal,
} from "reduxStore/selectors"
import { showModal } from "utils"
import PublicLayout from "./public"
import SubmitLayout from "./submit"

let previousPath = ""
let currentPath = ""

const Layouts = {
    public: PublicLayout,
    submit: SubmitLayout,
}

const Layout = ({ children }) => {
    const loggedIn = useSelector(selectIsLoggedIn)
    const userLoading = useSelector(selectIsUserLoading)
    const accountType = useSelector(selectAccountType)
    const current_modal = useSelector(selectCurrentAuthModal)
    const show_modal = useSelector(selectShowAuthModal)
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const { search, pathname } = location
    const query = qs.parse(search, { ignoreQueryPrefix: true })
    const { authAction, accType, onSuccess, privilegedAction } = query
    const showPrivilegedActionWarning = privilegedAction === "true"
    // const isEmailActionLink = () => /^\/auth(?=\/|$)/i.test(pathname)

    useLayoutEffect(() => {
        // NProgress & ScrollTop Management
        currentPath = pathname + search
        if (currentPath !== previousPath) {
            window.scrollTo(0, 0)
            // NProgress.start()
        }
        setTimeout(() => {
            // NProgress.done()
            previousPath = currentPath
        }, 300)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    useEffect(() => {
        if (onSuccess) dispatch(authActions.setState({ authAction: authAction || "", redirect: onSuccess }))
        if (!loggedIn && authAction && !userLoading) showModal("auth", authAction, { accType })
        if (loggedIn && authAction === "signup" && accType !== accountType && !userLoading)
            showModal("auth", authAction, { accType })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn, search])

    // Layout Rendering
    const getLayout = () => {
        if (/^\/user\/submit(?=\/|$)/i.test(pathname)) return "submit"
        return "public"
    }
    const Container = Layouts[getLayout()]
    const isUserLoggedIn = loggedIn
    const isUserLoading = userLoading
    const authenticating = show_modal && (current_modal === "login" || current_modal === "signup")

    const BootstrappedLayout = () => {
        console.log(
            `\nLoggedIn = ${isUserLoggedIn}, Path = ${pathname}, Query = ${search}, Loading = ${isUserLoading}\n\n`
        )
        // show loader when user in check authorization process, not logged in yet and not on login pages
        if (isUserLoading && !isUserLoggedIn && !authenticating) {
            return null
        }
        // else, render the requested page
        return <Container>{children}</Container>
    }

    return (
        <Fragment>
            <VerificationModal modalName="verification-required" />
            <ConfirmDetailsModal modalName="confirmation-required" />
            {/*Auth Modals*/}
            <LoginModal
                modalName="login"
                cancelCallback={() => {
                    dispatch(authActions.clearRedirection())
                    history.replace(location.pathname)
                }}
                showPrivilegedActionWarning={showPrivilegedActionWarning}
            />
            <SignupModal
                modalName="signup"
                cancelCallback={() => {
                    dispatch(authActions.clearRedirection())
                    history.replace(location.pathname)
                }}
                showPrivilegedActionWarning={showPrivilegedActionWarning}
            />
            <ForgotPasswordModal modalName="forgot-password" />
            {BootstrappedLayout()}
        </Fragment>
    )
}

export default Layout
