/* eslint-disable max-len */
const stagingId = "progresspicture"
const prodId = "progresspicture-prod"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const isEnvDev = Boolean(process.env.NODE_ENV === "development")

const appEnvironment = isEnvDev ? "development" : firebaseConfig.projectId === stagingId ? "staging" : "production"
const isEnvDevOrStaging = appEnvironment === "staging" || appEnvironment === "development"
const isEnvStaging = appEnvironment === "staging"
const isEnvProd = appEnvironment === "production"
const domainName = isEnvDev
    ? process.env.REACT_APP_DOMAIN_DEV
    : isEnvStaging
    ? process.env.REACT_APP_DOMAIN_STAGING
    : process.env.REACT_APP_DOMAIN_PROD

const imageURLPrefix = `https://firebasestorage.googleapis.com/v0/b/${
    isEnvDevOrStaging ? stagingId : prodId
}.appspot.com/o/assets%2F`

const imagesConfig = {
    homePageImage1: `${imageURLPrefix}ProgressPicture_Track_Fitness_Goals.png?alt=media`,
    homePageImage2: `${imageURLPrefix}ProgressPicture_Fitness_Transformations.png?alt=media`,
    homePageImage3: `${imageURLPrefix}ProgresPicture_Track_Goals.png?alt=media`,
    homePageImage4: `${imageURLPrefix}ProgressPicture_Share_Transformation.png?alt=media`,
    businessPageHeaderImage: `${imageURLPrefix}ProgressPicture_Business_Page_Header_Image.png?alt=media`,
    businessPageImage1: `${imageURLPrefix}ProgressPicture_Services.png?alt=media`,
    businessPageImage2: `${imageURLPrefix}ProgressPicture_Transformations.png?alt=media`,
    businessPageImage3: `${imageURLPrefix}ProgressPicture_Sorting.png?alt=media`,
    businessPageImage4: `${imageURLPrefix}ProgressPicture_Verified_Transformations.png?alt=media`,
    businessPageImage5: `${imageURLPrefix}ProgressPicture_Track_Client_Transformations.png?alt=media`,
    businessPageImage6: `${imageURLPrefix}ProgressPicture_Widget.png?alt=media`,
}

const getImageURL = (imageName) => {
    return imagesConfig[imageName]
}

const appConfig = {
    rootDir: __dirname,
    defaultPhotoUrl: `https://firebasestorage.googleapis.com/v0/b/progresspicture-prod.appspot.com/o/no-img.jpg?alt=media`,
    imageThumbnailSizes: {
        userAvatarImage: ["128x128"],
        planAvatarImage: ["345x180", "128x128"],
        transformationImage: ["300x600", "173x345"],
        trainerServiceImage: ["350x200", "175x100"],
        trainerPlanImage: ["400x200", "200x100"],
        headerImage: ["560x400", "200x100"],
    },
    // in MBs
    maxFileSizes: {
        userAvatarImage: 5,
        planAvatarImage: 10,
        transformationImage: 10,
        trainerServiceImage: 10,
        trainerPlanImage: 10,
        headerImage: 10,
    },
}

const algoliaConfig = {
    applicationId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    searchOnlyApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY,
}

module.exports = {
    firebaseConfig,
    isEnvDev,
    isEnvStaging,
    isEnvDevOrStaging,
    isEnvProd,
    domainName,
    appEnvironment,
    appConfig,
    algoliaConfig,
    getImageURL,
}
