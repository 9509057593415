import React, { useRef, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { AutoComplete, Button, Input } from "antd"
import { CloseOutlined, ManOutlined, SearchOutlined, WomanOutlined } from "@ant-design/icons"
import algoliaSearch from "algoliasearch/lite"
import { dobToAge } from "@progresspicture/shared/utils"
import { algoliaConfig, appEnvironment } from "config"
import "./styles.scss"

const client = algoliaSearch(algoliaConfig.applicationId, algoliaConfig.searchOnlyApiKey)
const indexPrefix = appEnvironment === "development" ? "dev" : appEnvironment === "staging" ? "staging" : "prod"
const index = client.initIndex(`${indexPrefix}_users`)

const NavbarSearch = ({ setShowSearch }) => {
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    const containerRef = useRef(null)
    const inputRef = useRef(null)
    const history = useHistory()

    React.useEffect(() => {
        inputRef.current.focus()
    }, [])

    const renderUser = (user) => (
        <Link
            to={user.accountType === "individual" ? `/profile/user/${user.username}` : `/coach/${user.username}`}
            className="result"
        >
            <img src={user.avatarUrl} alt={""} />
            <span className="name">{user.displayName}</span>
            {user.gender === "MALE" ? (
                <ManOutlined className="mid-blue" />
            ) : user.gender === "FEMALE" ? (
                <WomanOutlined className="mid-blue" />
            ) : null}
            <span className="mid-blue">{user.dob && dobToAge(user.dob)}</span>
            <span className="mid-blue">{user?.businessType}</span>
        </Link>
    )

    const handleSearch = (value) => {
        const showDropdown = value.length > 1
        setOpen(showDropdown)

        index
            .search(value)
            .then(({ hits }) => {
                let myOptions = []
                if (hits.length > 0)
                    myOptions.push({
                        label: "Users",
                        options: hits.map((hit) => ({
                            value: hit.username,
                            label: renderUser(hit),
                        })),
                    })
                setOptions(myOptions)
            })
            .catch(console.error)
    }

    const handleSelect = (_, option) => {
        setShowSearch(false)
        history.push(option.label.props.to)
    }

    return (
        <div className="NavbarSearch" ref={containerRef}>
            <div className="input">
                <AutoComplete
                    dropdownClassName="NavbarSearchDropdown"
                    dropdownMatchSelectWidth="100%"
                    style={{ width: "100%" }}
                    options={options}
                    open={open}
                    onSearch={handleSearch}
                    onSelect={handleSelect}
                    onBlur={() => setTimeout(() => setShowSearch(false), 100)}
                >
                    <Input
                        ref={inputRef}
                        size="large"
                        placeholder="Search transformations..."
                        width="100%"
                        prefix={<SearchOutlined className="text-faint" />}
                        // onChange={handleSearch}
                    />
                </AutoComplete>
            </div>
            <Button type="link" size="large" onClick={() => setShowSearch(false)}>
                <CloseOutlined />
            </Button>
        </div>
    )
}

export default NavbarSearch
