import { storage } from "services/firebase/init"

const uploadFile = async ({ file, cloudPath, metadata = {} }) => {
    const fileRef = storage.ref().child(cloudPath)
    try {
        const uploadTaskSnapshot = await fileRef.put(file, metadata)
        const downloadUrl = await uploadTaskSnapshot.ref.getDownloadURL()
        return { downloadUrl, fileRef }
    } catch (error) {
        throw cloudPath
    }
}

const uploadFiles = async (fileList) => {
    // fileList must be a list of objects containing {file, cloudPath, metadata}
    const uploads = fileList.map(uploadFile)
    try {
        await Promise.all(uploads)
        return true
    } catch (failedCloudPath) {
        const alreadyUploaded = fileList.filter(({ cloudPath }) => cloudPath !== failedCloudPath)
        const toDelete = alreadyUploaded.map(({ cloudPath }) => cloudPath)
        console.warn(`Deleting ${toDelete.length} uploaded files!`)
        await deleteFiles(toDelete)
        return false
    }
}

const deleteFile = async (cloudPath) => {
    await storage.ref().child(cloudPath).delete()
}

const deleteFiles = async (cloudFilePaths) => {
    const deletions = cloudFilePaths.map(deleteFile)
    return await Promise.all(deletions)
}

export { uploadFile, uploadFiles, deleteFile, deleteFiles }
