import React from "react"
import { useSelector } from "react-redux"
import { Modal } from "antd"
import ConfirmDetails from "components/auth/confirm-details"
import { selectCurrentAuthModal, selectShowAuthModal } from "reduxStore/selectors"
import { closeModal } from "utils"

const ConfirmDetailsModal = ({ modalName, cancelCallback }) => {
    const current_modal = useSelector(selectCurrentAuthModal)
    const show_modal = useSelector(selectShowAuthModal)
    return (
        <Modal
            title={<div style={{ textAlign: "center" }}>Confirmation Required</div>}
            centered
            visible={show_modal && current_modal === modalName}
            width={400}
            closable={false}
            keyboard={false}
            destroyOnClose={true}
            onCancel={() => closeModal("auth")}
            afterClose={cancelCallback}
            footer={""}
        >
            <ConfirmDetails />
        </Modal>
    )
}

export default ConfirmDetailsModal
