// Services related to a user (can be either individual or trainer)

import { getTransformationImageCloudPath } from "@progresspicture/shared/utils"
import { appConfig } from "config"
import { getTrackingData } from "services/api"
import { firebaseAuthServices } from "services/firebase"
import {
    createTransformationImageObjectsForUpload,
    deleteFile,
    generateTransformationTitle,
    getTransformationCloudPath,
    processProgressPicturesForUpload,
    progressPicturesContainImage,
    uploadFile,
    uploadFiles,
} from "utils"
import { generateRandomId } from "./etc"
import { db, remove, storage, union } from "./init"

const getTransformation = async ({ username, transformationId }) => {
    let transformationData = {}
    let transformationRef
    let transformationDoc
    if (transformationId) {
        transformationRef = db.doc(`/transformations/${transformationId}`)
        transformationDoc = await transformationRef.get()
        if (transformationDoc.exists)
            transformationData = { ...transformationDoc.data(), transformationId: transformationDoc.id }
    } else if (username) {
        transformationRef = db
            .collection("transformations")
            .where("username", "==", username)
            .where("status", "==", "normal")
            .limit(1)
        transformationDoc = await transformationRef.get()
        transformationDoc.forEach((doc) => (transformationData = { ...doc.data(), transformationId: doc.id }))
    }
    return transformationData
}

export const getTransformationById = async (transformationId) => {
    return getTransformation({ transformationId })
}

export const getTransformationByUsername = async (username) => {
    return getTransformation({ username })
}

export const getBasicUserDataByUsername = async (username) => {
    let userDoc = await db.doc(`/users/${username}`).get()
    if (!userDoc.exists) throw new Error(`User does not exist - ${username}`)
    return userDoc.data()
}

export const getAllTransformations = async () => {
    let transformations = {}
    const transformationDocs = await db
        .collection("transformations")
        .where("status", "==", "normal")
        .orderBy("createdOn")
        .get()
    transformationDocs.forEach((doc) => (transformations[doc.id] = { ...doc.data(), transformationId: doc.id }))
    console.log("Fetched ALL transformations!")
    return transformations
}

export const getAllPublicTransformations = async () => {
    let transformations = {}
    const transformationDocs = await db
        .collection("transformations")
        .where("postPrivacy", "==", "PUBLIC")
        .where("status", "==", "normal")
        .orderBy("createdOn")
        .get()
    transformationDocs.forEach((doc) => (transformations[doc.id] = { ...doc.data(), transformationId: doc.id }))
    console.log("Fetched ALL public transformations!")
    return transformations
}

export const getAllFilteredPublicTransformations = async (filters = {}, transformationsPerPage, lastTransformation) => {
    let filteredTransformations = {}
    let transformationRef = db.collection("transformations").where("postPrivacy", "==", "PUBLIC")
    if (filters.goal !== null) transformationRef = transformationRef.where("goal", "==", filters.goal)
    if (filters.gender !== null) transformationRef = transformationRef.where("gender", "==", filters.gender)
    if (filters.diet.length) transformationRef = transformationRef.where("diets", "array-contains-any", filters.diet)
    else if (filters.supplement.length)
        transformationRef = transformationRef.where("supplements", "array-contains-any", filters.supplement)
    else if (filters.sport.length)
        transformationRef = transformationRef.where("activities", "array-contains-any", filters.sport)
    transformationRef = transformationRef.orderBy("createdOn")
    if (lastTransformation) transformationRef = transformationRef.startAfter(lastTransformation)
    const transformationDocs = await transformationRef.limit(transformationsPerPage).get()
    transformationDocs.forEach((doc) => (filteredTransformations[doc.id] = { ...doc.data(), transformationId: doc.id }))
    let lastTransformationRef = {}
    if (transformationDocs.docs.length > 0)
        lastTransformationRef = transformationDocs.docs[transformationDocs.docs.length - 1].data().createdOn
    return { filteredTransformations, lastTransformationRef }
}

export const requestFollowUser = async (usernameFollower, displayNameFollower, avatarUrlFollower, usernameFollowed) => {
    const followedRef = db.doc(`/followers/${usernameFollowed}`)
    const followingUser = db.doc(`/users/${usernameFollower}`)
    const batch = db.batch()
    batch.update(followedRef, {
        followRequests: union({
            username: usernameFollower,
            displayName: displayNameFollower,
            avatarUrl: avatarUrlFollower,
        }),
    })
    batch.update(followingUser, { requestedToFollow: union(usernameFollowed) })
    return await batch.commit()
}

export const handleFollowRequestDecision = async (requestingUsername, ownUsername, decision) => {
    const requestingUserRef = db.doc(`users/${requestingUsername}`)
    const requestingUserData = (await requestingUserRef.get()).data()
    const followersRef = db.doc(`followers/${ownUsername}`)
    const followersData = (await followersRef.get()).data()
    const followRequests = followersData.followRequests || []
    let followRequest = followRequests.filter((req) => req.username === requestingUsername)
    if (!followRequest.length) throw new Error("Follow request not found!")

    if (decision === "approve") {
        const batch = db.batch()
        batch.update(followersRef, {
            usernames: union(requestingUsername),
            displayNames: union(requestingUserData.displayName),
            avatarUrls: union(requestingUserData.avatarUrl),
            followRequests: followRequests.filter((followReq) => followReq.username !== requestingUsername),
        })
        batch.update(requestingUserRef, {
            requestedToFollow: remove(ownUsername),
        })
        return await batch.commit()
    }

    if (decision === "deny") {
        return await followersRef.update({
            followRequests: followRequests.filter((followReq) => followReq.username !== requestingUsername),
        })
    }
}

export const markNotificationsAsViewed = async (username, notificationIds) => {
    const batch = db.batch()
    notificationIds.forEach((notificationId) => {
        const docRef = db.collection("notifications").doc(notificationId)
        batch.update(docRef, { viewed: union(username) })
    })
    return await batch.commit()
}

export const deleteTransformation = async (transformationId) => {
    const transformationRef = db.collection("transformations").doc(transformationId)
    const transformationData = (await transformationRef.get()).data()
    const commentRef = await db.collection("comments").where("transformationId", "==", transformationId).get()
    const likedTransformationsRef = await db
        .collection("likedTransformations")
        .where("transformationId", "==", transformationId)
        .get()
    const notificationsRef = await db
        .collection("notifications")
        .where("transformationId", "==", transformationId)
        .get()
    const transformationCloudPath = getTransformationCloudPath(transformationData.username, transformationId)

    const batch = db.batch()
    commentRef?.forEach((doc) => {
        batch.delete(doc.ref)
    })
    likedTransformationsRef?.forEach((doc) => {
        batch.delete(doc.ref)
    })
    notificationsRef?.forEach((doc) => {
        batch.delete(doc.ref)
    })
    batch.delete(transformationRef)

    let ref = storage.ref(transformationCloudPath)
    ref.listAll()
        .then((dir) => {
            dir.prefixes.forEach((fileRef) => {
                let fileRefs = storage.ref(fileRef.fullPath)
                fileRefs.listAll().then((dir) => {
                    dir.items.forEach(function (file) {
                        file.delete()
                    })
                })
            })
        })
        .catch((error) => {
            console.log(error)
        })
    return await batch.commit()
}

export const followUser = async (usernameFollower, displayNameFollower, avatarUrlFollower, usernameFollowed) => {
    const followedRef = db.doc(`/followers/${usernameFollowed}`)
    await followedRef.update({
        usernames: union(usernameFollower),
        displayNames: union(displayNameFollower),
        avatarUrls: union(avatarUrlFollower),
    })
    const { usernames = [], displayNames = [], avatarUrls = [] } = (await followedRef.get()).data()
    const followers = usernames.map((username, index) => ({
        username,
        displayName: displayNames[index],
        avatarUrl: avatarUrls[index],
    }))
    return followers
}

export const unfollowUser = async (usernameFollower, displayNameFollower, avatarUrlFollower, usernameUnfollowed) => {
    const followedRef = db.doc(`/followers/${usernameUnfollowed}`)
    const followingUser = db.doc(`/users/${usernameFollower}`)
    const { followRequests: existingFollowRequests } = (await followedRef.get()).data()
    const { requestedToFollow = [] } = (await followingUser.get()).data()

    if (requestedToFollow.includes(usernameUnfollowed)) {
        const batch = db.batch()
        batch.update(followedRef, {
            followRequests: existingFollowRequests.filter((req) => req.username !== usernameFollower),
        })
        batch.update(followingUser, { requestedToFollow: remove(usernameUnfollowed) })
        await batch.commit()
    } else {
        // FIXME: Potential to receive different displayNameFollower and avatarUrlFollower if not synced on change
        await followedRef.update({
            usernames: remove(usernameFollower),
            displayNames: remove(displayNameFollower),
            avatarUrls: remove(avatarUrlFollower),
        })
    }

    const { usernames = [], displayNames = [], avatarUrls = [] } = (await followedRef.get()).data()
    const followers = usernames.map((username, index) => ({
        username,
        displayName: displayNames[index],
        avatarUrl: avatarUrls[index],
    }))
    return followers
}

export const getFollowedUsers = async (ownUsername, usernamesOnly = false) => {
    const followedUsers = await db.collection("followers").where("usernames", "array-contains", ownUsername).get()

    if (usernamesOnly) return followedUsers.docs.map((doc) => doc.id)
    return followedUsers.docs.map((doc) => {
        const username = doc.id
        const { displayName, avatarUrl } = doc.data()
        return { username, displayName, avatarUrl }
    })
}

export const getFollowersAndFollowRequests = async (username) => {
    const {
        usernames = [],
        displayNames = [],
        avatarUrls = [],
        followRequests = [],
    } = (await db.doc(`/followers/${username}`).get()).data() || {}
    const followers = usernames.map((username, index) => ({
        username,
        displayName: displayNames[index],
        avatarUrl: avatarUrls[index],
    }))
    return { followers, followRequests }
}

export const getFollowersAndFollowing = async (username) => {
    const following = await getFollowedUsers(username)
    const { followers, followRequests } = await getFollowersAndFollowRequests(username)
    return { followers, following, followRequests }
}

export const markNotificationAsRead = async (notificationId, username) => {
    const notification = db.collection("notifications").doc(notificationId)
    return notification.update({ read: union(username), viewed: union(username) })
}

export const deleteProgressPicture = async (transformationId, progressPictureId) => {
    const transformationRef = db.doc(`/transformations/${transformationId}`)
    const transformationData = (await transformationRef.get()).data()
    let { progressPictures } = transformationData
    delete progressPictures[progressPictureId]
    const currentTimestamp = new Date().toISOString()
    const updatedTransformation = { ...transformationData, progressPictures, lastUpdated: currentTimestamp }
    return await transformationRef.update(updatedTransformation)
}

export const updateProgressPictureImage = async (
    username,
    transformationId,
    progressPictureId,
    imageAngle,
    file,
    mode,
    existingFilename = null
) => {
    if (!["replace", "append"].includes(mode))
        throw new Error(`Mode '${mode}' is invalid for updating progress picture image`)
    if (mode === "replace" && !existingFilename) throw new Error(`Filename required for file to replace`)

    const transformationRef = db.doc(`/transformations/${transformationId}`)
    const { objForStorage, objForDb } = createTransformationImageObjectsForUpload(
        file,
        imageAngle,
        username,
        transformationId,
        progressPictureId
    )

    if (mode === "replace") {
        // uploading new image
        await uploadFile(objForStorage)
        await deleteFile(
            getTransformationImageCloudPath(username, transformationId, progressPictureId, existingFilename)
        )
        // deleting old image
        console.log("Existing image updated!")
        // updating the db
        const transformation = (await transformationRef.get()).data()
        let existingImages = transformation?.progressPictures?.[progressPictureId]?.images?.[imageAngle]
        existingImages = existingImages.filter((imageObj) => imageObj.filename !== existingFilename)
        const updatedImages = [objForDb, ...existingImages]
        await transformationRef.update({
            [`progressPictures.${progressPictureId}.images.${imageAngle}`]: updatedImages,
        })
    } else {
        // uploading new image
        await uploadFile(objForStorage)
        console.log("New image uploaded!")
        // updating the db
        await transformationRef.update({
            [`progressPictures.${progressPictureId}.images.${imageAngle}`]: union(objForDb),
        })
    }
    const updatedTransformation = (await transformationRef.get()).data()
    return { ...updatedTransformation, transformationId }
}

export const addProgressPicture = async (username, transformationId, newProgressPictureData) => {
    const newProgressPictureId = generateRandomId()
    let newProgressPicture = { [newProgressPictureId]: { ...newProgressPictureData } }
    const transformationRef = db.doc(`/transformations/${transformationId}`)

    // check if transformation has any images
    const hasImage = progressPicturesContainImage(newProgressPicture)
    // processing/adding progress pictures to the transformation
    if (hasImage) {
        const { imagesToUpload, newProgressPictures } = processProgressPicturesForUpload(
            { ...newProgressPicture },
            transformationId,
            username
        )
        newProgressPicture = newProgressPictures[newProgressPictureId]
        // uploading the images
        const uploadSuccess = await uploadFiles(imagesToUpload)
        console.log("Images uploaded!")
    }

    // adding the progress picture in db
    await transformationRef.update({
        [`progressPictures.${newProgressPictureId}`]: hasImage
            ? newProgressPicture
            : newProgressPicture[newProgressPictureId],
    })
    const updatedTransformation = (await transformationRef.get()).data()
    return { ...updatedTransformation, transformationId }
}

export const postPersonalTransformation = async (transformationData, transformationId, userData, isNewUser) => {
    const currentTimestamp = new Date().toISOString()

    let newUser = undefined
    let location = userData.location

    // sign up first if new user
    if (isNewUser) {
        const { email, password, username, displayName, emailSubscription } = userData
        location = await getTrackingData()
        const { user, error: signupError } = await firebaseAuthServices.createUserAccount(
            {
                email,
                password,
                username,
                displayName,
                emailSubscription,
                location,
            },
            location
        )
        if (!user) return { signupError }
        else {
            newUser = user
        }
    }

    try {
        const newTransformation = {
            createdOn: currentTimestamp,
            lastUpdated: currentTimestamp,
            username: userData.username,
            displayName: userData.displayName,
            avatarUrl: userData?.avatarUrl || appConfig.defaultPhotoUrl,
            location: location,
            ...transformationData,
            likeCount: 0,
            commentCount: 0,
            status: "normal",
            isPersonal: true,
            title: generateTransformationTitle(userData.username),
        }

        // adding the transformation to db
        await db.collection("transformations").doc(transformationId).set(newTransformation)
        return { transformation: { ...newTransformation, transformationId }, user: newUser }
    } catch (error) {
        return { transformationError: error }
    }
}

export const updateTransformationData = async (updatedTransformationData, transformationId, transformationLimits) => {
    // Accepts the updatedTransformationData as an Object containing only the changed transformation values
    // The updated progressPictures object should contain only the updated data fields for the corresponding
    // progressPictureId;
    // progressPictures: {
    //     [progressPictureId1]: {updated data},
    //     [progressPictureId2]: {updated data},
    //     ...
    // }
    // If a progressPicture field has nested data that has been updated, the entire field should be resent

    // TODO: validate transformation data

    let dataToUpdate = { ...updatedTransformationData, lastUpdated: new Date().toISOString() }
    const existingTransformationRef = db.collection("transformations").doc(transformationId)
    let newProgressPictures = { ...dataToUpdate.progressPictures }
    let updatedProgressPictures = {}
    // if progressPictures updated, merging original and new values
    if (newProgressPictures && Object.keys(newProgressPictures).length > 0) {
        const { progressPictures: existingProgressPictures } = (await existingTransformationRef.get()).data()
        Object.keys(existingProgressPictures).forEach((progressPictureId) => {
            const newProgressPicture = newProgressPictures[progressPictureId] || {}
            const existingProgressPicture = existingProgressPictures[progressPictureId]
            const units = Object.keys(transformationLimits.liftingStats.values)
            // making sure liftingStats doesn't have 0 values
            // if the new liftingStats object is all zero values, delete liftingStats object
            if (newProgressPicture.liftingStats) {
                if (
                    Object.keys(newProgressPicture.liftingStats).every((statName) =>
                        units.every((unit) => newProgressPicture.liftingStats[statName][unit] === 0)
                    )
                ) {
                    delete existingProgressPicture.liftingStats && delete newProgressPicture.liftingStats
                    console.log(`deleted liftingStats for progressPictureId: ${progressPictureId}`)
                }
                // else, delete any 0 valued stat in liftingStats
                else {
                    Object.keys(newProgressPicture.liftingStats).forEach((statName) => {
                        if (units.some((unit) => newProgressPicture.liftingStats[statName][unit] === 0))
                            delete existingProgressPicture.liftingStats[statName] &&
                                delete newProgressPicture.liftingStats[statName]
                    })
                }
            }
            updatedProgressPictures[progressPictureId] = { ...existingProgressPicture, ...newProgressPicture }
        })
    }
    if (Object.keys(updatedProgressPictures).length) dataToUpdate.progressPictures = updatedProgressPictures
    await existingTransformationRef.update(dataToUpdate)
    const updatedTransformation = (await existingTransformationRef.get()).data()
    return { ...updatedTransformation, transformationId }
}
