import React from "react"
import { message } from "antd"
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons"
import * as XLSX from "xlsx"
import { commonSchema } from "@progresspicture/shared/schema"
import { limits } from "@progresspicture/shared/validation"

const { isValid, email: emailValidation, name: nameValidation, username: usernameValidation } = commonSchema

class ValidationError extends Error {
    constructor(message, fieldName) {
        super(`${fieldName} => ${message}`)
        this.name = "ValidationError"
        this.field = fieldName
    }
}

export const validationColors = {
    error: "#fb4d4f",
    success: "#1b90ff",
}
export const validationIcons = {
    error: <CloseCircleTwoTone twoToneColor={validationColors.error} />,
    success: <CheckCircleTwoTone twoToneColor={validationColors.success} />,
}

export const errorProcessor = (content, check) => {
    const success = check()
    return (
        <p style={{ color: success ? validationColors.success : validationColors.error, marginBottom: "5px" }}>
            <span style={{ marginRight: "10px" }}>{success ? validationIcons.success : validationIcons.error}</span>
            {content}
        </p>
    )
}

export const getFormattedUsernameError = (username, returnBool = false) => {
    let validCharacters = () => Boolean(username.match(new RegExp("^[a-zA-Z0-9_]+$")))
    let noDoubleUnderscores = () => !username.includes("__")
    let validStartAndEnd = () => Boolean(username.match(new RegExp("^[a-zA-Z0-9].*[a-zA-Z0-9]$")))
    let validLength = () => {
        return username.length >= limits.username.min && username.length <= limits.username.max
    }
    if (returnBool) {
        return validCharacters() && noDoubleUnderscores() && validStartAndEnd() && validLength()
    }
    return (
        <div>
            {errorProcessor(
                <>
                    Can contain <u>letters</u>, <u>numbers</u> and <u>underscores</u>
                </>,
                validCharacters
            )}
            {errorProcessor(`Must be ${limits.username.min}-${limits.username.max} characters long`, validLength)}
            {errorProcessor("Must not contain double underscores", noDoubleUnderscores)}
            {errorProcessor("Must start and end with a letter or number", validStartAndEnd)}
        </div>
    )
}

export const getFormattedPasswordError = (password, returnBool = false) => {
    let validLength = () => password.length >= limits.password.min && password.length <= limits.password.max
    let hasUppercaseLetter = () => Boolean(password.match(new RegExp("^(?=.*[A-Z]+.*).+$")))
    let hasNumber = () => Boolean(password.match(new RegExp("^(?=.*[0-9]+.*).+$")))
    // let noSpaces = () => Boolean(password.match(new RegExp("^[^\\s]+$")))
    // let hasSpecialCharacter = () => (Boolean(password.match(new RegExp("[#?!@$%^&*-]"))))
    if (returnBool) {
        return hasUppercaseLetter() && hasNumber() && validLength()
    }
    return (
        <div>
            {errorProcessor(`Must be at least ${limits.password.min} characters long`, validLength)}
            {errorProcessor("Must contain a number", hasNumber)}
            {errorProcessor("Must contain a capital letter", hasUppercaseLetter)}
        </div>
    )
}

export const fileSizeWarning = (maxFileSizeMb) => {
    message.error(`Max File Size Exceeded : ${maxFileSizeMb} MB`)
}

/** @deprecated pre-requisite: requires porting usage of the userSignup function to backend */
export const validateSignupData = (newUserData) => {
    const { username, displayName, email } = newUserData
    // validate username
    if (!isValid(username, usernameValidation)) throw new ValidationError("Invalid username", "username")
    // validate displayName
    if (!isValid(displayName, nameValidation)) throw new ValidationError("Invalid displayName", "displayName")
    // validate email
    if (!isValid(email, emailValidation)) throw new ValidationError("Invalid email address", "email")
}

export const isValidSheet = (sheet, cols = []) => {
    const headers = getHeaders(sheet)
    let validCols = cols.map((colname) => ({ name: colname, found: false }))
    validCols = validCols.map((col) => {
        if (headers.find((header) => col.name === header)) {
            return { ...col, found: true }
        }
        return col
    })
    if (validCols.find((ele) => !ele.found)) {
        return false
    }
    return true
}

const getHeaders = (sheet) => {
    var header = 0,
        offset = 1
    var hdr = []
    var o = {}
    if (sheet == null || sheet["!ref"] == null) return []
    var range = o.range !== undefined ? o.range : sheet["!ref"]
    var r
    if (o.header === 1) header = 1
    else if (o.header === "A") header = 2
    else if (Array.isArray(o.header)) header = 3
    switch (typeof range) {
        case "string":
            r = safe_decode_range(range)
            break
        case "number":
            r = safe_decode_range(sheet["!ref"])
            r.s.r = range
            break
        default:
            r = range
    }
    if (header > 0) offset = 0
    var rr = XLSX.utils.encode_row(r.s.r)
    var cols = new Array(r.e.c - r.s.c + 1)
    for (var C = r.s.c; C <= r.e.c; ++C) {
        cols[C] = XLSX.utils.encode_col(C)
        var val = sheet[cols[C] + rr]
        switch (header) {
            case 1:
                hdr.push(C)
                break
            case 2:
                hdr.push(cols[C])
                break
            case 3:
                hdr.push(o.header[C - r.s.c])
                break
            default:
                if (val === undefined) continue
                hdr.push(XLSX.utils.format_cell(val))
        }
    }
    return hdr
}

const safe_decode_range = (range) => {
    var o = { s: { c: 0, r: 0 }, e: { c: 0, r: 0 } }
    var idx = 0,
        i = 0,
        cc = 0
    var len = range.length
    for (idx = 0; i < len; ++i) {
        if ((cc = range.charCodeAt(i) - 64) < 1 || cc > 26) break
        idx = 26 * idx + cc
    }
    o.s.c = --idx

    for (idx = 0; i < len; ++i) {
        if ((cc = range.charCodeAt(i) - 48) < 0 || cc > 9) break
        idx = 10 * idx + cc
    }
    o.s.r = --idx

    if (i === len || range.charCodeAt(++i) === 58) {
        o.e.c = o.s.c
        o.e.r = o.s.r
        return o
    }

    for (idx = 0; i !== len; ++i) {
        if ((cc = range.charCodeAt(i) - 64) < 1 || cc > 26) break
        idx = 26 * idx + cc
    }
    o.e.c = --idx

    for (idx = 0; i !== len; ++i) {
        if ((cc = range.charCodeAt(i) - 48) < 0 || cc > 9) break
        idx = 10 * idx + cc
    }
    o.e.r = --idx
    return o
}
