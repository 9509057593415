import React from "react"
import { useSelector } from "react-redux"
import { Modal } from "antd"
import ForgotPassword from "components/auth/forgot-password"
import { selectCurrentAuthModal, selectShowAuthModal } from "reduxStore/selectors"
import { closeModal, showModal } from "utils"

const ForgotPasswordModal = ({ modalName, cancelCallback }) => {
    const current_modal = useSelector(selectCurrentAuthModal)
    const show_modal = useSelector(selectShowAuthModal)

    return (
        <Modal
            title={"Forgot Password?"}
            centered
            visible={show_modal && current_modal === modalName}
            width={400}
            closable={true}
            keyboard={true}
            destroyOnClose={true}
            onCancel={() => closeModal("auth")}
            afterClose={cancelCallback}
            footer={""}
        >
            <ForgotPassword changePrompt={() => showModal("auth", "login")} />
        </Modal>
    )
}

export default ForgotPasswordModal
