// Login Form

import React from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { Button, Divider, Form, Input, Typography } from "antd"
import { limits } from "@progresspicture/shared/validation"
import GoogleAuth from "components/auth/google-auth"
import { authActions } from "reduxStore"
import { selectIsUserLoading } from "reduxStore/selectors"
import { showModal } from "utils"
import "../styles.auth.scss"

const Login = ({ changePrompt, subTitle }) => {
    const loading = useSelector(selectIsUserLoading)
    const dispatch = useDispatch()

    // On form submission (success)
    const onFinish = (values) => {
        const { email, password } = values
        dispatch(authActions.login({ email, password }))
    }

    // On form submission (failure)
    const onFinishFailed = ({ values }) => {
        console.log("Error in submitting values : ", values)
    }

    const [form] = Form.useForm()

    return (
        <div>
            <Helmet>
                <title>Login</title>
                <meta name="description" content="ProgressPicture login form" />
            </Helmet>
            {subTitle ? <p className="sub-title">{subTitle}</p> : ""}
            <div className="auth-container">
                <GoogleAuth />

                <Divider plain className="text-muted">
                    or login with your email
                </Divider>

                <Form
                    form={form}
                    layout="vertical"
                    hideRequiredMark
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="mb-4 mt-2"
                >
                    {/*Email*/}
                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: "Please enter your email address" },
                            { type: "email", message: "Please enter your email address" },
                        ]}
                    >
                        <Input
                            data-se="email-address-field"
                            size="large"
                            placeholder="E-mail address"
                            maxLength={120}
                            className="input-field"
                        />
                    </Form.Item>

                    {/*Password*/}
                    <Form.Item name="password" rules={[{ required: true, message: "Please enter your password" }]}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Input.Password
                                data-se="password-field"
                                type="password"
                                size="large"
                                placeholder="Password"
                                maxLength={limits.password.max}
                                className="input-field"
                                style={{ flexGrow: 1 }}
                            />
                            {/*Forgot password button*/}
                            <Button
                                type="link"
                                onClick={() => showModal("auth", "forgot-password")}
                                style={{ paddingRight: 0 }}
                            >
                                Forgot?
                            </Button>
                        </div>
                    </Form.Item>

                    <Button
                        data-se="login-button"
                        type="primary"
                        size="large"
                        className="auth-button"
                        htmlType="submit"
                        loading={loading}
                        block
                    >
                        Login
                    </Button>
                </Form>
            </div>
            <Divider className="m-0" />
            <div className="exception-text">
                Don't have an account?
                <Typography.Link onClick={changePrompt}>
                    <strong>Create account</strong>
                </Typography.Link>
            </div>
        </div>
    )
}

export default Login
