import { createSlice } from "@reduxjs/toolkit"
import uniqueArray from "lodash/uniq"
import { getUnitPreference } from "@progresspicture/shared/utils"
import initialState from "./initialState"

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setState: (state, action) => {
            const { payload } = action
            Object.keys(payload).forEach((key) => {
                state[key] = payload[key]
            })
        },
        updateUserData: (state, action) => {
            const { payload } = action
            state.userData = { ...state.userData, ...payload }
        },
        addTransformation: (state, action) => {
            const { transformationId, transformation } = action.payload
            state.transformations[transformationId] = transformation
        },
        enterSubmissionFlow: (state) => {
            state.inSubmissionFlow = true
        },
        exitSubmissionFlow: (state) => {
            state.inSubmissionFlow = false
        },
        toggleUnitPreference: (state) => {
            const toggledUnitType = state.userData.unitPreference.unit === "metric" ? "imperial" : "metric"
            state.userData.unitPreference = getUnitPreference(toggledUnitType)
        },
        updateLikedTransformations: (state, action) => {
            const { payload } = action
            state.likedTransformations = uniqueArray(payload)
        },
        deleteProgressCheckIn: (state, action) => {
            const { transformationId, progressPictureId } = action.payload
            delete state.transformations[transformationId].progressPictures[progressPictureId]
        },
        notificationRead: (state, action) => {
            const { notificationId } = action.payload
            const userNotifications = state.notifications
            const username = state.userData.username
            const readNotification = userNotifications.filter(
                (notification) => notification.notificationId === notificationId
            )[0]
            const index = userNotifications.indexOf(readNotification)
            state.notifications[index].read = uniqueArray([...readNotification.read, username])
            state.notifications[index].viewed = uniqueArray([...readNotification.viewed, username])
        },
        notificationsViewed: (state, action) => {
            const { viewedNotificationIds } = action.payload
            state.notifications = state.notifications.map((notification) =>
                viewedNotificationIds.includes(notification.notificationId)
                    ? { ...notification, viewed: uniqueArray(notification.viewed.concat(state.userData.username)) }
                    : { ...notification }
            )
        },
        removeNotification: (state, action) => {
            const { notificationId } = action.payload
            state.notifications = state.notifications.filter(
                (notification) => notification.notificationId !== notificationId
            )
        },
        deleteNotifications: (state, action) => {
            const { transformationId } = action.payload
            state.notifications = state.notifications.filter(
                (notification) => notification.transformationId !== transformationId
            )
        },
        addFollowRequest: (state, action) => {
            const { username: requestedUser } = action.payload
            state.userData.requestedToFollow = uniqueArray([...(state.userData.requestedToFollow || []), requestedUser])
        },
        removeFollowRequest: (state, action) => {
            const { username: removedUser } = action.payload
            state.userData.requestedToFollow = (state.userData.requestedToFollow || []).filter(
                (req) => req !== removedUser
            )
        },
        userFollowed: (state, action) => {
            const { username: followedUser } = action.payload
            state.following = uniqueArray([...state.following, followedUser])
        },
        userUnfollowed: (state, action) => {
            const { username: unfollowedUser } = action.payload
            state.following = state.following.filter((username) => username !== unfollowedUser)
        },
        // saga action creators
        updateSetting: () => {},
    },
})

export default userSlice
