import React from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { Button, Form, Input, notification } from "antd"
import { limits } from "@progresspicture/shared/validation"
import { userActions } from "reduxStore"
import { selectUsername } from "reduxStore/selectors"
import { checkUsername, confirmAccountDetails } from "services/api"
import { closeModal, getFormattedUsernameError, validationColors, validationIcons } from "utils"
import "../styles.auth.scss"

let timeout = null

const ConfirmDetails = () => {
    const username = useSelector(selectUsername)
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [validateStatus, setValidateStatus] = React.useState("success")
    const [accountType, setAccountType] = React.useState("individual")
    const [loading, setLoading] = React.useState(false)

    // On form submission (success)
    const onFinish = async (values) => {
        if (validateStatus === "success") {
            setLoading(true)
            try {
                values = { ...values, username: values.username.toLowerCase() }
                const successMsg = await confirmAccountDetails(values)
                dispatch(userActions.updateUserData(values))
                notification.success({ message: successMsg })
                closeModal("auth")
            } catch (error) {
                console.error(error)
                notification.error({ message: error })
            } finally {
                setLoading(false)
            }
        } else {
            console.error("Invalid username")
            setValidateStatus("error")
        }
    }

    // On form submission (failure)
    const onFinishFailed = ({ values }) => {
        console.error("Error in submitting values : ", values)
        if (validateStatus !== "success") {
            console.error("Invalid username")
            setValidateStatus("error")
        }
    }

    const onValuesChange = (changedValues, allValues) => {
        const { username } = changedValues
        // if username field has changed
        if (username !== undefined) {
            clearTimeout(timeout)
            // if username is empty
            if (username === "") {
                setValidateStatus("")
                form.setFields([
                    {
                        name: "username",
                        errors: [],
                    },
                ])
            } else {
                // if username matches valid regex
                if (username.match(limits.username.regex)) {
                    setValidateStatus("validating")
                    form.setFields([
                        {
                            name: "username",
                            errors: [],
                        },
                    ])
                    timeout = setTimeout(() => {
                        checkUsername(username)
                            .then((successMsg) => {
                                setValidateStatus("success")
                                form.setFields([
                                    {
                                        name: "username",
                                        errors: [
                                            <>
                                                <div style={{ padding: "2px 5px", color: validationColors.success }}>
                                                    <span style={{ marginRight: "10px" }}>
                                                        {validationIcons.success}
                                                    </span>
                                                    {successMsg}
                                                </div>
                                            </>,
                                        ],
                                    },
                                ])
                                console.log(successMsg)
                            })
                            .catch((errorMsg) => {
                                setValidateStatus("error")
                                form.setFields([
                                    {
                                        name: "username",
                                        errors: [
                                            <>
                                                <div style={{ padding: "2px 5px" }}>
                                                    <span style={{ marginRight: "10px" }}>{validationIcons.error}</span>
                                                    {errorMsg}
                                                </div>
                                            </>,
                                        ],
                                    },
                                ])
                                console.error(errorMsg)
                            })
                    }, 1500)
                } else {
                    setValidateStatus("error")
                    form.setFields([
                        {
                            name: "username",
                            errors: [getFormattedUsernameError(username)],
                        },
                    ])
                }
            }
        }
    }

    return (
        <div className="auth-container">
            <Helmet>
                <title>Confirmation</title>
                <meta name="description" content="ProgressPicture account details confirmation form" />
            </Helmet>
            <p className="confirmation__text">Please confirm or update your details to finalize account creation.</p>
            <p className="confirmation__note">Note: The username update is permanent.</p>
            <Form
                form={form}
                layout="vertical"
                hideRequiredMark
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="mb-4 mt-2"
                onValuesChange={onValuesChange}
                initialValues={{
                    username: username || "",
                    businessName: "",
                    accountType: accountType,
                }}
            >
                {/*Username*/}
                <Form.Item
                    name="username"
                    label={<div className="form-label">Username</div>}
                    hasFeedback
                    validateStatus={validateStatus}
                >
                    <Input size="large" placeholder="Username" className="input-field" />
                </Form.Item>

                {/*Business Name*/}
                {accountType === "trainer" && (
                    <Form.Item
                        name="businessName"
                        hasFeedback
                        rules={[
                            {
                                required: false,
                                message: "Allowed characters are letters, numbers, underscores, space and dash.",
                                pattern: limits.name.regex,
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder="Business Name"
                            maxLength={limits.name.max}
                            className="input-field"
                        />
                    </Form.Item>
                )}

                <Button type="primary" size="large" className={`confirm`} htmlType="submit" loading={loading}>
                    <strong>Confirm</strong>
                </Button>
            </Form>
        </div>
    )
}

export default ConfirmDetails
