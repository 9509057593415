const initialState = {
    limits: {},
    options: {
        labels: {},
        allowedValues: {},
    },
    constants: {},
    defaults: {},
}

export default initialState
