import store from "store"
import { firebaseAuth } from "services/firebase/init"
import axios from "services/httpService"

// Header 'Provider' is required for all authenticated requests

export const continueWithGoogle = async (email, googleIdToken, accountType, location) => {
    const { token } = await axios.post(
        accountType === "individual" ? `/user/login/google` : `/trainer/login/google`,
        { googleIdToken, email, location },
        { noAuth: true }
    )
    return token
}

export const signup = async (signupData) => {
    const { token } = await axios.post(
        signupData.accountType === "individual" ? `/user/signup` : `/trainer/signup`,
        { ...signupData },
        { noAuth: true }
    )
    // TODO: set the token in the store and perform firebase login in caller function
    firebaseAuth.signInWithCustomToken(token).then((userCredential) => console.log({ userCredential }))
    store.set("authToken", token)
    store.set("authProvider", "email_password")
    return token
}

// logs in the user with an email/password or a Google id token
export const login = async (email, password) => {
    const { token } = await axios.post(`/login`, { email, password }, { noAuth: true })
    // TODO: set the token in the store and perform firebase login in caller function
    store.set("authToken", token)
    store.set("authProvider", "email_password")
    firebaseAuth.signInWithCustomToken(token).then((userCredential) => console.log({ userCredential }))
    return token
}

export const logout = async () => {
    const { success } = await axios.get(`/logout`)
    // TODO: remove the token from the store in caller function
    store.remove("authToken")
    store.remove("authProvider")
    return success
}

export const confirmAccountDetails = async (accountDetails) => {
    const { success } = await axios.post(`/user/confirm`, { ...accountDetails })
    return success
}

export const verifyEmailAddress = async (verificationToken) => {
    const { emailAddress } = await axios.get(`/user/verify/email/${verificationToken}`, { noAuth: true })
    return emailAddress
}

export const verifyResetToken = async (resetToken) => {
    const { success } = await axios.get(`/user/verify/reset/${resetToken}`, { noAuth: true })
    return success
}

export const forgotPassword = async (email) => {
    const { success } = await axios.post(`/user/forgot`, { email }, { noAuth: true })
    return success
}

export const resetPassword = async (newPassword) => {
    const { success } = await axios.post(
        `/user/reset`,
        { newPassword, token: store.get("resetToken") },
        { noAuth: true }
    )
    return success
}

export const resendVerificationEmail = async () => {
    const { success } = await axios.get(`/user/resend/verification`)
    return success
}
