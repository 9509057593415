import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Button, Modal } from "antd"
import store from "store"
import { authActions } from "reduxStore"
import {
    selectAllowAddPersonalTransformation,
    selectCurrentAuthModal,
    selectEmailAddress,
    selectIsOwnProfilePage,
    selectPersonalTransformationId,
    selectShowAuthModal,
} from "reduxStore/selectors"
import { closeModal } from "utils"
import "../styles.modal.scss"

const VerificationModal = ({ modalName, cancelCallback }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const allowAddTransformation = useSelector(selectAllowAddPersonalTransformation)
    const isOwnProfilePage = useSelector(selectIsOwnProfilePage)
    const transformationId = useSelector(selectPersonalTransformationId)
    const showModal = useSelector(selectShowAuthModal)
    const currentModal = useSelector(selectCurrentAuthModal)
    const email = useSelector(selectEmailAddress)
    const [resendEmailVerificationTimer, setResendEmailVerificationTimer] = useState(0)
    const emailVerificationTimer = 65

    useEffect(() => {
        const emailExpiryTime = store.get(`verificationEmail_${email}`)
        let emailVerificationTimeout = emailExpiryTime ? JSON.parse(emailExpiryTime) : 0
        if (emailVerificationTimeout?.expiryTime > new Date().getTime())
            setResendEmailVerificationTimer(
                Math.floor((emailVerificationTimeout?.expiryTime - new Date().getTime()) / 1000)
            )
    }, [email])

    useEffect(() => {
        if (resendEmailVerificationTimer > 0) {
            const interval = setInterval(() => {
                setResendEmailVerificationTimer(resendEmailVerificationTimer - 1)
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [resendEmailVerificationTimer])

    const handleResend = () => {
        dispatch(authActions.resendVerificationEmail({ email: email }))
        setResendEmailVerificationTimer(emailVerificationTimer)
        const expiryTime = new Date().getTime() + emailVerificationTimer * 1000
        store.set(`verificationEmail_${email}`, JSON.stringify({ expiryTime }))
    }

    return (
        <Modal
            title="Account Created!"
            centered
            visible={showModal && currentModal === modalName}
            width={400}
            closable={true}
            keyboard={true}
            destroyOnClose={true}
            onCancel={() => closeModal("auth")}
            afterClose={cancelCallback}
            footer={null}
        >
            <div className="verification">
                <p>
                    Welcome to ProgressPicture. To complete setup, we've just sent you an email to verify your account.
                </p>
                <p className="icons">🎉🎉🎉🎉</p>
                <div className="buttons">
                    {allowAddTransformation && (
                        <Link to="/user/submit">
                            <Button type="primary" size="large">
                                Add transformation
                            </Button>
                        </Link>
                    )}
                    {/* TODO: Cater for trainer profiles as well */}
                    {transformationId && !isOwnProfilePage && (
                        <Button
                            size="large"
                            onClick={() => {
                                history.push(`/transformation/${transformationId}`)
                                closeModal("auth")
                            }}
                        >
                            Go to Profile
                        </Button>
                    )}
                </div>
                <p>
                    Didn't receive the email?
                    {resendEmailVerificationTimer < 1 ? (
                        <a onClick={handleResend}> Click here </a>
                    ) : (
                        <span className="mid-blue"> Click here ({resendEmailVerificationTimer}s) </span>
                    )}
                    to resend.
                </p>
            </div>
        </Modal>
    )
}

export default VerificationModal
