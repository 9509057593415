import store from "store"
import axios from "services/httpService"

// gets the user data from the server for the current account
export const getUserData = async () => {
    const { userData } = await axios.get(`/user`)
    return userData
}

export const updateUserData = async (updatedData) => {
    const { updatedUserData } = await axios.post(`/user`, { ...updatedData })
    return updatedUserData
}

export const changeEmailAddress = async (newEmail) => {
    const { userData } = await axios.post(`/user/change_email_address`, { newEmail })
    return userData
}

export const submitEnquiry = async (enquiryData) => {
    const { userData } = await axios.post(`/user/service/enquiry`, { enquiryData })
    return userData
}

export const changePassword = async (oldPassword, newPassword) => {
    const { token } = await axios.post(`/user/change_password`, {
        oldPassword,
        newPassword,
    })
    store.set("authToken", token)
    return token
}

export const updateUserAvatar = async (file) => {
    const formData = new FormData()
    formData.append("avatar", file)
    const { avatarUrl } = await axios.post(`/user/avatar`, formData)
    return avatarUrl
}

export const deleteUserAvatar = async () => {
    const { newAvatarUrl } = await axios.delete(`/user/avatar`)
    return newAvatarUrl
}

export const checkUsername = async (username) => {
    const { success } = await axios.get(`/check_username?username=${username}`, { noAuth: true })
    return success
}

export const suggestUsername = async (hint) => {
    const { suggestedUsername } = await axios.get(`/suggest_username?hint=${hint}`)
    return suggestedUsername
}

export const unsubscribeFromEmail = async (unsubscribeToken) => {
    const { success } = await axios.get(`/user/unsubscribe/${unsubscribeToken}`, { noAuth: true })
    return success
}

export const deleteUserAccount = async (username, deletionType) => {
    // TODO: password should be verified before deleting account
    const { success } = await axios.delete(`/user/${username}?deletionType=${deletionType}`)
    return success
}

export const getClientAndInviteData = async (inviteId) => {
    return await axios.get(`/user/verification/invite/${inviteId}`)
}

export const respondToTrainerInvite = async (data) => {
    const { success } = await axios.post(`/user/invite/response`, data)
    return { success }
}
