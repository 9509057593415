// eslint-disable-next-line no-unused-vars
import React from "react"
import { withRouter } from "react-router-dom"
import Navbar from "components/ui/Navbar"

const SubmitLayout = ({ children, location: { pathname } }) => {
    const navBarClear = () => {
        const clearForPaths = ["/", "/home", "/user/submit", "/business"]
        return clearForPaths.includes(pathname)
    }

    return (
        <>
            <Navbar clear={navBarClear()} />
            <div className="Content">{children}</div>
        </>
    )
}

export default withRouter(SubmitLayout)
