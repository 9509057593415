import store from "store"
import { getUnitPreference, insertIf } from "@progresspicture/shared/utils"
import { defaultLocationData, getDefaultFollowerData, Unit } from "@progresspicture/shared/validation"
import { appConfig, isEnvDevOrStaging } from "config"
import { getTrackingData } from "services/api"
import {
    getUsernameFromUid,
    queryLikedTransformations,
    queryUserNotifications,
    queryUserTransformations,
} from "services/firebase/queries"
import { validateSignupData } from "utils"
import { db, firebaseAuth } from "./init"
import { getFollowedUsers } from "./index"

export const createUserAccount = async (signupData, trackingData = null, inviteId = null) => {
    const {
        email,
        password,
        username,
        displayName,
        businessName,
        emailSubscription = false,
        accountType = "individual",
    } = signupData
    // getting user's location data
    const location = trackingData || (await getTrackingData())
    const isTestAccount = isEnvDevOrStaging
    const signupWithInvite = inviteId ? (await db.collection("invites").doc(inviteId).get()).exists : false
    const bypassEmailVerification = isTestAccount || signupWithInvite

    try {
        // create new user data object
        let newUserData = {
            // received data
            email: email,
            username: username.toLowerCase(),
            displayName: displayName,
            ...insertIf(accountType === "trainer", { businessName }),
            emailSubscription: !!emailSubscription,
            // additional static data
            accountType: accountType,
            role: "user",
            provider: "email_password",
            ...insertIf(accountType === "trainer", { businessType: "Fitness business" }),
            emailVerified: Boolean(bypassEmailVerification) || false,
            signupWithInvite,
            avatarUrl: appConfig.defaultPhotoUrl,
            requireAccountDetails: false,
            userPrivacy: "PUBLIC",
            deleted: false,
            test: Boolean(isTestAccount),
            location: location || defaultLocationData,
            unitPreference: getUnitPreference(Unit.METRIC),
            verificationStatus: {},
        }

        // check if username is already taken
        let userDoc = await db.doc(`/users/${newUserData.username}`).get()
        if (userDoc.exists) throw new Error(`The username '${newUserData.username}' is already taken`)

        // validate the signup data
        validateSignupData(newUserData)

        // perform user signup
        const userCredential = await firebaseAuth.createUserWithEmailAndPassword(email, password)
        if (userCredential.user) {
            let uid = userCredential.user.uid
            let token = await userCredential.user.getIdToken()
            const currentTimestamp = new Date().toISOString()
            let newUser = {
                uid,
                createdOn: currentTimestamp,
                lastUpdated: currentTimestamp,
                ...newUserData,
            }
            // adding the new user to the database
            await db.doc(`/users/${newUser.username}`).set(newUser)
            // adding a default follower document for the user
            await db.doc(`/followers/${newUser.username}`).set(
                getDefaultFollowerData({
                    username: newUser.username,
                    displayName: newUser.displayName,
                    avatarUrl: newUser.avatarUrl,
                })
            )
            // successfully registered user - returning firebase user & auth token
            store.set("authToken", token)
            store.set("authProvider", "email_password")
            return { user: userCredential.user }
        } else return { error: "Unknown error in user signup!" }
    } catch (error) {
        console.error(error)
        // if firebase auth error
        if (error.code) {
            if (error.code === "auth/email-already-in-use") return { error: "Email is already in use" }
            if (error.code === "auth/weak-password") return { error: "Inadequate password strength" }
        }
        // if custom/other error
        else return { error: error.message }
    }
}

export const login = async ({ email, password }) => {
    try {
        const userCredential = await firebaseAuth.signInWithEmailAndPassword(email, password)
        let token = await userCredential.user.getIdToken()
        store.set("authToken", token)
        store.set("authProvider", "email_password")
        return { user: userCredential.user }
    } catch (error) {
        console.error(error)
        if (error.code === "auth/wrong-password" || error.code === "auth/user-not-found") {
            return { error: "Invalid email or password" }
        } else if (error.code === "auth/too-many-requests") {
            return { error: error.message }
        } else if (error.code === "auth/user-disabled") {
            return { error: "This account has been deleted" }
        } else return { error: "Invalid email or password" }
    }
}

export const logout = async () => {
    await firebaseAuth.signOut()
    store.remove("authToken")
    store.remove("authProvider")
    return true
}

export const getUserData = async () => {
    let userLoaded = false
    const getCurrentUser = async () => {
        return new Promise((resolve, reject) => {
            if (userLoaded) resolve(firebaseAuth.currentUser)
            const unsubscribe = firebaseAuth.onAuthStateChanged(async (user) => {
                userLoaded = true
                unsubscribe()
                let userData = {}
                if (user) {
                    const username = await getUsernameFromUid(user.uid)
                    let userDoc = await db.doc(`/users/${username}`).get()
                    userData.userData = userDoc.data()
                    userData.likedTransformations = await queryLikedTransformations(username)
                    userData.notifications = await queryUserNotifications(username)
                    userData.transformations = await queryUserTransformations(username)
                    const following = await getFollowedUsers(username, true)
                    // userData.followers = followers
                    userData.following = following
                    resolve({ user, userData })
                }
                reject()
            }, reject)
        })
    }

    try {
        let response = await getCurrentUser()
        const { user, userData } = response
        return { currentUser: user, data: userData }
    } catch {
        console.error("Failed to get user data!")
        return null
    }
}
