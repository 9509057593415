// Signup Form

import React from "react"
import { CountryRegionData } from "react-country-region-selector"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { Button, Checkbox, Divider, Form, Input, Select } from "antd"
import qs from "qs"

import store from "store"
import { limits } from "@progresspicture/shared/validation"
import GoogleAuth from "components/auth/google-auth"
import { authActions } from "reduxStore"
import { selectInSubmissionFlow, selectIsUserLoading } from "reduxStore/selectors"
import { checkUsername, suggestUsername } from "services/api"
import { getFormattedPasswordError, getFormattedUsernameError, validationColors, validationIcons } from "utils"
import "../styles.auth.scss"

let timeout = null

const Signup = ({ isSubmitting, subTitle, onSuccess, accountType }) => {
    const userLoading = useSelector(selectIsUserLoading)
    const inSubmissionFlow = useSelector(selectInSubmissionFlow)
    const dispatch = useDispatch()

    const [form] = Form.useForm()
    const [validateStatus, setValidateStatus] = React.useState("")
    const loading = isSubmitting === undefined ? userLoading : isSubmitting

    const { search } = useLocation()
    const queryString = qs.parse(search, { ignoreQueryPrefix: true })

    // On form submission (success)
    const onFinish = (values) => {
        if (validateStatus === "success") {
            let signupData = {
                username: form.getFieldValue("username"),
                accountType,
                ...values,
            }
            if (accountType === "trainer" && values.country) {
                const [countryName, countryCode] = CountryRegionData.find((country) => {
                    const [_, countryCode] = country
                    return countryCode === values.country
                })
                signupData = {
                    ...signupData,
                    location: { countryCode, countryName },
                }
            }
            const expiryInSeconds = 65
            const expiryTime = new Date().getTime() + expiryInSeconds * 1000
            store.set(`verificationEmail_${values.email}`, JSON.stringify({ expiryTime }))
            if (onSuccess) onSuccess(signupData)
            else {
                dispatch(authActions.signup({ ...signupData }))
            }
        } else {
            console.error("Invalid username")
            setValidateStatus("error")
            form.setFields([
                {
                    name: "username",
                    errors: [],
                },
            ])
        }
    }

    // On form submission (failure)
    const onFinishFailed = ({ values }) => {
        console.error("Error in submitting values : ", values)
        if (validateStatus !== "success") {
            console.error("Invalid username")
            setValidateStatus("error")
        }
    }

    const getHintFromName = (name) => {
        return name?.replaceAll(" ", "_")?.replaceAll("-", "_")
    }

    const getUsernameSuggestion = () => {
        let username = form.getFieldValue("username")
        let displayName = form.getFieldValue("displayName")
        let businessName = form.getFieldValue("businessName")
        let hint = getHintFromName(businessName) || getHintFromName(displayName) || ""
        if (hint && hint.match(limits.username.regex)) {
            suggestUsername(hint)
                .then((suggestedUsername) => {
                    setValidateStatus("success")
                    form.setFields([
                        {
                            name: "username",
                            value: suggestedUsername,
                        },
                    ])
                })
                .catch(console.error)
        }
        if (username && !hint) {
            setValidateStatus("")
            form.setFields([
                {
                    name: "username",
                    value: "",
                },
            ])
        }
    }

    const onValuesChange = (changedValues, allValues) => {
        const { username } = changedValues
        // if username field has changed
        if (username !== undefined) {
            clearTimeout(timeout)
            // if username is empty
            if (username === "") {
                setValidateStatus("")
                form.setFields([
                    {
                        name: "username",
                        errors: [],
                    },
                ])
            } else {
                // if username matches valid regex
                if (username.match(limits.username.regex)) {
                    setValidateStatus("validating")
                    form.setFields([
                        {
                            name: "username",
                            errors: [],
                        },
                    ])
                    timeout = setTimeout(() => {
                        checkUsername(username)
                            .then((successMsg) => {
                                setValidateStatus("success")
                                form.setFields([
                                    {
                                        name: "username",
                                        errors: [
                                            <>
                                                <div style={{ padding: "2px 5px", color: validationColors.success }}>
                                                    <span style={{ marginRight: "10px" }}>
                                                        {validationIcons.success}
                                                    </span>
                                                    {successMsg}
                                                </div>
                                            </>,
                                        ],
                                    },
                                ])
                                console.log(successMsg)
                            })
                            .catch((errorMsg) => {
                                setValidateStatus("error")
                                form.setFields([
                                    {
                                        name: "username",
                                        errors: [
                                            <>
                                                <div style={{ padding: "2px 5px" }}>
                                                    <span style={{ marginRight: "10px" }}>{validationIcons.error}</span>
                                                    {errorMsg}
                                                </div>
                                            </>,
                                        ],
                                    },
                                ])
                                console.error(errorMsg)
                            })
                    }, 1500)
                } else {
                    setValidateStatus("error")
                    form.setFields([
                        {
                            name: "username",
                            errors: [getFormattedUsernameError(username)],
                        },
                    ])
                }
            }
        }
    }

    return (
        <div>
            <Helmet>
                <title>Signup</title>
                <meta name="description" content="ProgressPicture signup form" />
            </Helmet>
            {subTitle ? <p className="sub-title">{subTitle}</p> : ""}
            <div className="auth-container">
                <Form
                    form={form}
                    layout="vertical"
                    hideRequiredMark
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    onValuesChange={onValuesChange}
                    initialValues={{
                        accountType: accountType,
                        emailSubscription: false,
                        tosAccepted: false,
                    }}
                >
                    {/*TODO: Remove conditional when federated auth is fixed*/}
                    {!inSubmissionFlow && (
                        // TODO: Separate the FB stuff into a <FacebookAuth> component
                        <>
                            <GoogleAuth accountType={accountType} />
                            <Divider plain className="text-muted">
                                or sign up with
                            </Divider>
                        </>
                    )}

                    {/*Name*/}
                    <Form.Item
                        name="displayName"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "",
                            },
                            {
                                message: "Allowed characters are letters, numbers, underscores, space and dash.",
                                pattern: limits.name.regex,
                            },
                        ]}
                    >
                        <Input
                            data-se="name-field"
                            onBlurCapture={getUsernameSuggestion}
                            size="large"
                            placeholder="Name"
                            maxLength={limits.name.max}
                            className="input-field"
                        />
                    </Form.Item>

                    {/*Business Name*/}
                    {accountType === "trainer" && (
                        <Form.Item
                            data-se="business-name-field"
                            name="businessName"
                            hasFeedback
                            rules={[
                                {
                                    required: false,
                                    message: "Allowed characters are letters, numbers, underscores, space and dash.",
                                    pattern: limits.name.regex,
                                },
                            ]}
                        >
                            <Input
                                onBlurCapture={getUsernameSuggestion}
                                size="large"
                                placeholder="Business Name"
                                maxLength={limits.name.max}
                                className="input-field"
                            />
                        </Form.Item>
                    )}

                    {/*Username*/}
                    <Form.Item name="username" hasFeedback validateStatus={validateStatus}>
                        <Input
                            data-se="username-field"
                            size="large"
                            placeholder="Username"
                            className="input-field"
                            autoComplete="username"
                        />
                    </Form.Item>

                    {/*Country*/}
                    {accountType === "trainer" && (
                        <Form.Item data-se="country-field" name="country" hasFeedback>
                            <Select size="large" placeholder="Country" showSearch>
                                {CountryRegionData.map((country) => {
                                    const [countryName, countryCode] = country
                                    return (
                                        <Select.Option data-se="country-options" key={countryCode} value={countryCode}>
                                            {countryName}
                                        </Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    )}

                    {/*Email*/}
                    <Form.Item
                        name="email"
                        initialValue={queryString?.email}
                        hasFeedback
                        rules={[
                            { required: true, message: "" },
                            { type: "email", message: "" },
                        ]}
                    >
                        <Input
                            data-se="email-address-field"
                            size="large"
                            placeholder="E-mail address"
                            maxLength={120}
                            className="input-field"
                            autoComplete="email"
                        />
                    </Form.Item>

                    {/*Password*/}
                    <Form.Item
                        name="password"
                        hasFeedback
                        rules={[
                            () => ({
                                validator(rule, value) {
                                    if (!value) {
                                        return Promise.reject()
                                    } else if (value.match(limits.password.regex)) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject(getFormattedPasswordError(value))
                                    }
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            data-se="password-field"
                            size="large"
                            placeholder="Password"
                            maxLength={limits.password.max}
                            className="input-field"
                            autoComplete="new-password"
                        />
                    </Form.Item>

                    {/*Confirm Password*/}
                    {/* <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {required: true, message: ''},
                            ({getFieldValue}) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject('The two passwords do not match!')
                                },
                            }),
                        ]}
                    >
                        <Input type="password" size="large" placeholder="Confirm Password"
                               maxLength={limits.password.max}
                               className="input-field"/>
                    </Form.Item> */}

                    <Form.Item
                        name={"tosAccepted"}
                        valuePropName="checked"
                        validateTrigger={"onSubmit"}
                        rules={[
                            () => ({
                                validator(rule, value) {
                                    if (!value)
                                        return Promise.reject("* Please review and accept the terms and conditions")
                                    return Promise.resolve()
                                },
                            }),
                        ]}
                    >
                        <Checkbox data-se="agree-tos-checkbox">
                            I agree to the{" "}
                            <a target="_blank" href="/legal/terms-of-service">
                                Terms and conditions
                            </a>{" "}
                            and{" "}
                            <a target="_blank" href="/legal/privacy-policy">
                                Privacy policy
                            </a>
                        </Checkbox>
                    </Form.Item>

                    {/* <Form.Item name={"emailSubscription"} valuePropName="checked">
                        <Checkbox>I want updates, recipes, workouts and lifestyle tips straight to my inbox</Checkbox>
                    </Form.Item> */}

                    <Button
                        data-se="signup-button"
                        block
                        type="primary"
                        size="large"
                        className="auth-button"
                        htmlType="submit"
                        loading={loading}
                    >
                        {accountType === "trainer" ? "Start 14-day free trial" : "Sign up"}
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default Signup
