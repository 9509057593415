import { getUnitPreference } from "@progresspicture/shared/utils"
import { Unit } from "@progresspicture/shared/validation"

const initialState = {
    userData: {
        accountType: "",
        emailVerified: false,
        unitPreference: getUnitPreference(Unit.METRIC),
        requestedToFollow: [],
    },
    likedTransformations: [],
    notifications: [],
    transformations: {},
    following: [],
    // followers: [],
    loggedIn: false,
    role: "user",
    loading: false,
    inSubmissionFlow: false,
    show_modal: false,
    current_modal: "",
    modalContext: {},
}

export default initialState
