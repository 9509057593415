import axios from "axios"
import { defaultLocationData } from "@progresspicture/shared/validation"
import { firebase, firebaseAuth } from "services/firebase/init"
import { createDownloadableFile } from "utils"

export const setupGoogleTokenRefresh = (googleUser) => {
    // Timing to renew access token
    let refreshTiming = (googleUser.tokenObj.expires_in || 3600 - 5) * 1000

    const refreshToken = async () => {
        const newAuthRes = await googleUser.reloadAuthResponse()
        const newGoogleIdToken = newAuthRes.id_token
        refreshTiming = (newAuthRes.expires_in || 3600 - 5) * 1000
        localStorage.setItem("authToken", newGoogleIdToken)
        firebaseAuth
            .signInWithCredential(firebase.auth.GoogleAuthProvider.credential(newGoogleIdToken))
            .then((userCredential) => console.log("Refreshed google auth token!"))
        // set up the other timer after the first one
        setTimeout(refreshToken, refreshTiming)
    }
    // set up first refresh timer
    setTimeout(refreshToken, refreshTiming)
}

export const setupEmailPasswordTokenRefresh = (user) => {
    const secondsTillRefresh = 30 * 60
    // Timing to renew access token
    let refreshTiming = secondsTillRefresh * 1000
    const refreshToken = async () => {
        let token = await user.getIdToken(true)
        localStorage.setItem("authToken", token)
        // set up the other timer after the first one
        setTimeout(refreshToken, refreshTiming)
    }
    // set up first refresh timer
    setTimeout(refreshToken, refreshTiming)
}

export const getTrackingData = async () => {
    // Fetches the user's location data
    try {
        const {
            IPv4,
            city,
            country_code: countryCode,
            country_name: countryName,
            latitude,
            longitude,
            postal: postalCode,
            state,
        } = (await axios.get(`https://geolocation-db.com/json/`)).data
        return { IPv4, city, countryCode, countryName, latitude, longitude, postalCode, state }
    } catch (error) {
        console.error(error)
        return defaultLocationData
    }
}

export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
    })
}

export const downloadImage = async (url, downloadFilename) => {
    const response = await fetch(url, { method: "get" })
    const blob = await response.blob()
    createDownloadableFile(blob, downloadFilename)
}
