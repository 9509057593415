import React from "react"
import { Helmet } from "react-helmet"
import { Button, Form, Input, notification } from "antd"
import { forgotPassword } from "services/api"
import "../styles.auth.scss"

const ForgotPassword = ({ changePrompt }) => {
    const [loading, setLoading] = React.useState(false)
    const [form] = Form.useForm()
    // On form submission (success)
    const onFinish = ({ email }) => {
        setLoading(true)
        forgotPassword(email)
            .then((successMsg) => {
                form.resetFields()
                notification.success({ message: successMsg, description: email })
            })
            .catch((errorMsg) => notification.error({ message: errorMsg, description: email, duration: 0 }))
            .finally(() => setLoading(false))
    }

    // On form submission (failure)
    const onFinishFailed = ({ values }) => console.log("Error in submitting values : ", values)

    return (
        <div className="forgot_password">
            <Helmet>
                <title>Forgot Password</title>
                <meta name="description" content="ProgressPicture forgot password form" />
            </Helmet>
            <div className="auth-container">
                <Form
                    form={form}
                    layout="vertical"
                    hideRequiredMark
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="mb-4 mt-2"
                >
                    <p className="forgot_password__text">
                        Don't worry! Just fill in your email and we'll send you a link to reset your password.
                    </p>

                    {/*Email*/}
                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: "" },
                            { type: "email", message: "" },
                        ]}
                    >
                        <Input size="large" placeholder="E-mail address" maxLength={120} className="input-field" />
                    </Form.Item>

                    <Button type="primary" size="large" className="auth-button" htmlType="submit" loading={loading}>
                        Reset Password
                    </Button>
                    <Button type="link" size="large" className="change-prompt" onClick={changePrompt}>
                        Back to login
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default ForgotPassword
