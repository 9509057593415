import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { createBrowserHistory } from "history"
import { createReduxStore } from "./reduxStore"
import Router from "./router"
import "./styles/antd.less"
import "./styles/main.scss"

const history = createBrowserHistory()
const reduxStore = createReduxStore(history)

ReactDOM.render(
    <Provider store={reduxStore}>
        <Router history={history} />
    </Provider>,
    document.getElementById("root")
)

export { reduxStore, history }
