import React from "react"
import { useGoogleLogin } from "react-google-login"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "antd"
import { authActions } from "reduxStore"
import { selectAuthModalContext } from "reduxStore/selectors"
import { setupGoogleTokenRefresh } from "services/api"
import "../styles.auth.scss"

const GoogleAuth = () => {
    const dispatch = useDispatch()
    const authModalContext = useSelector(selectAuthModalContext)
    const { accType = "individual" } = authModalContext
    const onSuccess = (googleUser) => {
        dispatch(
            authActions.continueWithGoogle({
                email: googleUser.profileObj.email,
                googleIdToken: googleUser.tokenId,
                accountType: accType,
            })
        )

        setupGoogleTokenRefresh(googleUser)
    }

    const onFailure = (err) => {
        console.error("Failed to login with Google (client-side)")
        console.error(err)
    }

    const { signIn, loaded } = useGoogleLogin({
        onSuccess,
        onFailure,
        clientId: process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID,
        // accessType: 'offline',
        cookiePolicy: "single_host_origin",
        // isSignedIn: true,
        // responseType: 'code',
        // prompt: 'consent',
    })

    return (
        <div className="social-buttons">
            <Button size="large" type="text" className="social-button" onClick={signIn}>
                <img src="/icons/google.svg" alt="google login" className="social-button__icon" />
            </Button>
            {/* TODO: Connect Facebook login */}
            {/*<Button*/}
            {/*    size="large"*/}
            {/*    className="social-button"*/}
            {/*    // onClick={signIn}*/}
            {/*>*/}
            {/*    <img src="/icons/facebook.svg" alt="facebook login" className="social-button__icon"/>*/}
            {/*</Button>*/}
        </div>
    )
}

export default GoogleAuth
