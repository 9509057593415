import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { AiOutlineComment, AiOutlineLike, AiOutlineUsergroupAdd } from "react-icons/ai"
import { CgGym } from "react-icons/cg"
import { GiProgression, GiWeightLiftingUp } from "react-icons/gi"
import { SiGooglecalendar } from "react-icons/si"
import { message } from "antd"
import cloneDeep from "lodash/cloneDeep"
import isEmpty from "lodash/isEmpty"
import objectPath from "object-path"
import {
    dobToAge,
    getTransformationImageCloudPath,
    getTransformationImageFilename,
    insertIf,
    sanitizeFilename,
    sortProgressPicturesByDate,
} from "@progresspicture/shared/utils"
import { appConfig, firebaseConfig, isEnvDev } from "config"
import { reduxStore } from "index"
import { userActions } from "reduxStore"
import modalActions from "reduxStore/modal/actions"
import { deleteUserAvatar, updateUserAvatar } from "services/api"

export const getNiceDate = (date, showYear = false) => {
    return showYear
        ? new Intl.DateTimeFormat([], {
              month: "short",
              day: "numeric",
              year: "numeric",
          }).format(date)
        : new Intl.DateTimeFormat([], {
              month: "short",
              day: "numeric",
          }).format(date)
}

export const itemDifference = (firstValue, secondValue) => {
    let diff = 0
    if (firstValue && firstValue !== 0 && secondValue && secondValue !== 0) diff = firstValue - secondValue
    return diff
}

export const formatDateString = (date, format) => {
    const nth = (d) => {
        if (d > 3 && d < 21) return d + "th"
        switch (d % 10) {
            case 1:
                return d + "st"
            case 2:
                return d + "nd"
            case 3:
                return d + "rd"
            default:
                return d + "th"
        }
    }
    switch (format) {
        case "MMMM-DD-YYYY":
            return `${new Date(date).toLocaleString("default", { month: "long" })} ${nth(
                new Date(date).getDate()
            )} ${new Date(date).getFullYear()}`

        default:
            return `${nth(new Date(date).getDate())} ${new Date(date).toLocaleString("default", {
                month: "short",
            })} ${new Date(date).getFullYear()}`
    }
}

export const generateTagsFromTransformation = (transformation, transformationLimits) => {
    let tags = []
    transformation.goal &&
        tags.push({
            type: "goal",
            value: transformation.goal,
            tag: transformationLimits.goal.options[transformation.goal],
        })
    transformation.diets &&
        tags.push(
            ...transformation.diets.map((diet) => ({
                type: "diet",
                value: diet,
                tag: transformationLimits.diets.options[diet],
            }))
        )
    transformation.activities &&
        tags.push(
            ...transformation.activities.map((activity) => ({
                type: "sport",
                value: activity,
                tag: transformationLimits.activities.options[activity],
            }))
        )
    // transformation.supplements && tags.push(...transformation.supplements.map((supplement) => ({
    //     type: 'supplement',
    //     value: supplement,
    //     tag: transformationLimits.supplements.options[supplement],
    // })))
    return tags
}

export function useQuery() {
    // TODO: Parse the query with `qs.parse(search, {ignoreQueryPrefix: true}) instead of URLSearchParams`
    return new URLSearchParams(useLocation().search)
}

export const updateNotificationInfo = (notification) => {
    // if 'link' is missing, notification will only be marked as read on click
    switch (notification.type) {
        case "like":
            return {
                ...notification,
                message: (
                    <>
                        <span className="text-medium">{notification.sender} </span>
                        <span>has liked your transformation</span>
                    </>
                ),
                icon: <AiOutlineLike className="icon" />,
                link: `/transformation/${notification.transformationId}`,
            }
        case "comment":
            return {
                ...notification,
                message: (
                    <>
                        <span className="text-medium">{notification.sender} </span>
                        <span>has commented on your transformation</span>
                    </>
                ),
                icon: <AiOutlineComment className="icon" />,
                link: `/transformation/${notification.transformationId}#comments`,
            }
        case "newFollower":
            return {
                ...notification,
                message: (
                    <>
                        <span className="text-medium">{notification.sender} </span>
                        <span>has followed your transformation</span>
                    </>
                ),
                icon: <AiOutlineUsergroupAdd className="icon" />,
                link: `/profile/user/${notification.sender}`,
            }
        case "followAccepted":
            return {
                ...notification,
                message: (
                    <>
                        <span className="text-medium">
                            <b>{notification.sender} </b>
                        </span>
                        <span>accepted your follow request</span>
                    </>
                ),
                icon: <AiOutlineUsergroupAdd className="icon" />,
                link: `/profile/user/${notification.sender}`,
            }
        case "newTransformation":
            return {
                ...notification,
                message: (
                    <>
                        <span className="text-medium">{notification.sender} </span>
                        <span>posted a new transformation</span>
                    </>
                ),
                icon: <GiWeightLiftingUp className="icon" />,
                link: `/transformation/${notification.transformationId}`,
            }
        case "newProgressCheckin":
            return {
                ...notification,
                message: (
                    <>
                        <span className="text-medium">{notification.sender} </span>
                        <span>has uploaded a new progress picture. View it here.</span>
                    </>
                ),
                icon: <GiProgression className="icon" />,
                link: `/transformation/${notification.transformationId}`,
            }
        case "newService":
            return {
                ...notification,
                message: (
                    <>
                        <span className="text-medium">{notification.sender} </span>
                        <span>is offering a new service</span>
                    </>
                ),
                icon: <CgGym className="icon" />,
            }
        case "newPlan":
            return {
                ...notification,
                message: (
                    <>
                        <span className="text-medium">{notification.sender} </span>
                        <span>posted a new transformation plan</span>
                    </>
                ),
                icon: <SiGooglecalendar className="icon" />,
            }
        case "clientVerificationInvite":
            return {
                ...notification,
                message: (
                    <>
                        <span className="text-medium">
                            <b>{notification.sender} </b>
                        </span>
                        <span>requested verification for their post</span>
                    </>
                ),
                icon: <AiOutlineUsergroupAdd className="icon" />,
            }
        case "clientInviteAccepted":
            return {
                ...notification,
                message: (
                    <>
                        <span className="text-medium">
                            <b>{notification.sender} </b>
                        </span>
                        <span>approved your verification request</span>
                    </>
                ),
                icon: <AiOutlineUsergroupAdd className="icon" />,
                link: "/user/dashboard#transformations",
            }
        case "clientInviteRejected":
            return {
                ...notification,
                message: (
                    <>
                        <span className="text-medium">
                            <b>{notification.sender} </b>
                        </span>
                        <span>declined your verification request</span>
                    </>
                ),
                icon: <AiOutlineUsergroupAdd className="icon" />,
            }
        default:
            return { ...notification }
    }
}

// TODO: Provide initializations and convert data on frontend so that data access to the database can be simplified

export const initWeight = (transformationLimits = {}) => {
    const weightInit = {}
    Object.keys(transformationLimits).length > 0 &&
        Object.keys(transformationLimits.weight).forEach((unit) => (weightInit[unit] = 0))
    return weightInit
}

export const createDownloadableFile = (blob, filename) => {
    const a = document.createElement("a")
    document.body.appendChild(a)
    let url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = filename
    a.className = "display: none"
    a.click()
    window.URL.revokeObjectURL(url)
}

export const getPreviewUrl = (file) =>
    window.URL ? window.URL.createObjectURL(file) : window.webkitURL.createObjectURL(file)

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    })
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }

        window.addEventListener("resize", handleResize)
        handleResize()
        return () => window.removeEventListener("resize", handleResize)
    }, [])
    return windowSize
}

export const changeAvatar = (file) => {
    updateUserAvatar(file)
        .then((avatarUrl) => {
            reduxStore.dispatch(userActions.updateUserData({ avatarUrl }))
            message.success("User avatar changed")
        })
        .catch((error) => {
            console.error(error)
            message.error("Failed to update avatar")
        })
}

// TODO: To be tested and integrated
export const removeAvatar = () => {
    deleteUserAvatar()
        .then((avatarUrl) => {
            reduxStore.dispatch(userActions.updateUserData({ avatarUrl }))
            message.success("User avatar removed")
        })
        .catch((error) => {
            console.error(error)
            message.error("Failed to update avatar")
        })
}

export const openInNewTab = (url) => {
    const newWindow = window.open(`//${url}`, "_blank", "noopener,noreferrer")
    if (newWindow) newWindow.opener = null
}

export const getTrainerHeaderFilename = (filename, thumbnailSize = null) =>
    `${thumbnailSize ? `thumb@${thumbnailSize}_` : ""}${filename}`

export const getTrainerHeaderCloudPath = (trainerUsername, filename) =>
    `users/${trainerUsername}/header-images/${filename}`

export const getTransformationCloudPath = (username, transformationId) =>
    `users/${username}/transformations/${transformationId}/progress-pictures/`

export const getCloudStorageUrl = (destinationFilePath) => {
    if (isEnvDev)
        return `http://localhost:9199/download/storage/v1/b/${firebaseConfig.storageBucket}/o/${encodeURIComponent(
            destinationFilePath
        )}?alt=media`
    return `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/${encodeURIComponent(
        destinationFilePath
    )}?alt=media`
}

export const showModal = (modalType, modalName, modalContext = { accType: "individual" }) => {
    reduxStore.dispatch({
        type: modalActions.showModal,
        payload: { modalType: modalType, modalName: modalName, ...insertIf(!isEmpty(modalContext), { modalContext }) },
    })
}

export const closeModal = (modalType) =>
    reduxStore.dispatch({ type: modalActions.closeModal, payload: { modalType: modalType } })

/** @deprecated */
// TODO: porting this one is a little tricky. It had a different signature in the old code
//    and it's not clear what the new signature should be.
export const generateTransformationTitle = (username) => {
    let name = username ? `${username}'s ` : ""
    // let goal = toTitleCase(data.goal)
    // return `${name} ${goal}`
    return name + `Untitled Transformation`
}

export const createTransformationImageObjectsForUpload = (
    imageFile,
    imageAngle,
    username,
    transformationId,
    progressPictureId
) => {
    const safeFilename = sanitizeFilename(`${new Date().toISOString()}_${imageFile.name}`)
    const filename = getTransformationImageFilename(safeFilename, imageAngle)
    const cloudPath = getTransformationImageCloudPath(username, transformationId, progressPictureId, filename)
    const metadata = { contentType: imageFile.type }
    let objForStorage = { file: imageFile, cloudPath, metadata }

    let objForDb = {}
    objForDb["filename"] = filename
    objForDb["url"] = getCloudStorageUrl(cloudPath)
    appConfig.imageThumbnailSizes["transformationImage"].forEach((size) => {
        const thumbFilename = getTransformationImageFilename(safeFilename, imageAngle, size)
        const thumbFilePath = getTransformationImageCloudPath(
            username,
            transformationId,
            progressPictureId,
            thumbFilename
        )
        objForDb[`thumb${size}`] = getCloudStorageUrl(thumbFilePath)
    })
    return { objForStorage, objForDb }
}

export const processProgressPicturesForUpload = (progressPictures, transformationId, username) => {
    let imagesToUpload = []
    let imagesForDb = {}
    // processing ProgressPicture images
    Object.keys(progressPictures).forEach((progressPictureId) => {
        const images = progressPictures[progressPictureId].images || {}
        Object.keys(images).forEach((imageAngle) => {
            // create files list to upload
            const imageFile = images[imageAngle]
            const { objForStorage, objForDb } = createTransformationImageObjectsForUpload(
                imageFile,
                imageAngle,
                username,
                transformationId,
                progressPictureId
            )
            imagesToUpload.push(objForStorage)
            // create image data for the db
            objectPath.set(imagesForDb, `${progressPictureId}.${imageAngle}.0`, { ...objForDb })
        })
    })

    let newProgressPictures = cloneDeep(progressPictures)
    Object.keys(progressPictures).forEach(
        (progressPictureId) => (newProgressPictures[progressPictureId]["images"] = imagesForDb[progressPictureId])
    )

    return { imagesToUpload, newProgressPictures }
}

export const progressPicturesContainImage = (progressPictures) =>
    progressPictures &&
    !!Object.keys(progressPictures).filter((progressPictureId) => !!progressPictures[progressPictureId]?.images).length

export const getDefaultBeforeAndAfterView = (progressPictures = {}, defaultProgressView = {}, returnIds = false) => {
    // returns an object with 'before' and 'after' progress pictures for the current progress view
    // if there are no progress pictures, returns null for both
    // also returns the default imageAngle for the before and after progress pictures
    // and can optionally return ids for the before and after progress pictures instead of progress pictures

    let beforeId = null
    let afterId = null
    let imageAngle = defaultProgressView.imageAngle || "FRONT"

    const sortedProgressPictureIds = sortProgressPicturesByDate(progressPictures)
    if (sortedProgressPictureIds.length === 0) {
        // if no progress pictures, return default
        return { before: {}, after: {}, imageAngle }
    }

    // if defaultProgressView contains 'before' and 'after' fields, set beforeId and afterId to those values
    // else, set beforeId to the first progress picture id,
    // and afterId to the last progress picture id if there are more than 1 progress pictures
    if (defaultProgressView.before && defaultProgressView.after) {
        beforeId = defaultProgressView.before
        afterId = defaultProgressView.after
    } else {
        beforeId = sortedProgressPictureIds[0]
        afterId =
            sortedProgressPictureIds.length > 1 ? sortedProgressPictureIds[sortedProgressPictureIds.length - 1] : null
    }

    // if returnIds is true, return beforeId and afterId instead of progress pictures
    if (returnIds) {
        return { before: beforeId, after: afterId, imageAngle }
    } else {
        return {
            before: { ...progressPictures[beforeId], progressPictureId: beforeId },
            after: { ...progressPictures[afterId], progressPictureId: afterId },
            imageAngle,
        }
    }
}

export const filtered = ({ transformations, transformationIds, filters }) =>
    transformationIds.filter((transformationId) => {
        const transformation = transformations[transformationId]
        const { search, goal, duration, gender, sport, diet, supplement, service } = filters
        const { progressPictures } = transformation
        let sortedIds = sortProgressPicturesByDate(progressPictures)

        // if (weight) {
        //     if (!progressPictures || !progressPictures[sortedIds[0]]?.WEIGHT) return false
        //     const itemWeight = progressPictures[sortedIds[0]].WEIGHT[unitPreference.weight]
        //     const sensitivity = itemWeight * 0.15
        //     if (itemWeight < weight - sensitivity) return false
        //     if (itemWeight > weight + sensitivity) return false
        // }

        // if (height) {
        //     if (!transformation.height) return false
        //     const itemHeight = transformation.height[unitPreference.height]
        //     const sensitivity = itemHeight * 0.15
        //     if (itemHeight < height - sensitivity) return false
        //     if (itemHeight > height + sensitivity) return false
        // }

        // if (age) {
        //     const sensitivity = age * 0.15
        //     if (dobToAge(transformation.dob) < age - sensitivity) return false
        //     if (dobToAge(transformation.dob) > age + sensitivity) return false
        // }

        if (duration) {
            let itemDuration = 0
            if (sortedIds.length > 1)
                itemDuration =
                    new Date(progressPictures[sortedIds[sortedIds.length - 1]].date) -
                    new Date(progressPictures[sortedIds[sortedIds.length - 2]].date)
            const itemMonths = itemDuration / (1000 * 60 * 60 * 24 * 30)
            const sensitivity = duration / 2
            if (itemMonths < duration - sensitivity) return false
            if (itemMonths > duration + sensitivity) return false
        }

        if (search && transformation.displayName.toLowerCase().search(search.toLowerCase()) === -1) return false

        if (goal !== null && transformation.goal !== goal) return false

        if (gender !== null && gender !== "ANY" && transformation?.gender !== gender) return false

        if (sport?.length && !transformation?.activities?.find((item) => sport?.includes(item.toUpperCase())))
            return false

        if (diet?.length && !transformation?.diets?.find((item) => diet?.includes(item.toUpperCase()))) return false

        if (
            supplement?.length &&
            !transformation?.supplements?.find((item) => supplement?.includes(item.toUpperCase()))
        )
            return false

        if (service && service !== transformation.service) return false

        return true
    })

export const sorted = ({
    transformations,
    transformationIds,
    unitPreference,
    filters,
    sort = "Recent",
    services,
    commentsIntoLikes,
}) => {
    const filteredTransformationIds = filtered({
        transformations,
        transformationIds,
        unitPreference,
        filters,
        services,
    })
    return filteredTransformationIds.sort((transformationIdOne, transformationIdTwo) => {
        const transformationOne = transformations[transformationIdOne]
        const transformationTwo = transformations[transformationIdTwo]
        const { age, weight, height } = filters

        let transformationOneWeight = 0,
            transformationOneHeight = 0
        let transformationOneProgressPictureIds = sortProgressPicturesByDate(
            transformationOne.progressPictures,
            "descending"
        )
        if (transformationOne?.progressPictures?.[transformationOneProgressPictureIds[0]]?.WEIGHT) {
            transformationOneWeight =
                transformationOne.progressPictures[transformationOneProgressPictureIds[0]].WEIGHT[unitPreference.weight]
        }
        if (transformationOne?.height?.[unitPreference.height])
            transformationOneHeight = transformationOne.height[unitPreference.height]
        let transformationOneRelevanceScore =
            Math.abs(weight && transformationOneWeight - weight) +
            Math.abs(height && transformationOneHeight - height) +
            Math.abs(age && dobToAge(transformationOne.dob) - age)

        let transformationTwoWeight = 0,
            transformationTwoHeight = 0
        let transformationTwoProgressPictureIds = sortProgressPicturesByDate(
            transformationTwo.progressPictures,
            "descending"
        )
        if (transformationTwo?.progressPictures?.[transformationTwoProgressPictureIds[0]]?.WEIGHT) {
            transformationTwoWeight =
                transformationTwo.progressPictures[transformationTwoProgressPictureIds[0]].WEIGHT[unitPreference.weight]
        }
        if (transformationTwo?.height?.[unitPreference.height])
            transformationTwoHeight = transformationTwo.height[unitPreference.height]
        let transformationTwoRelevanceScore =
            Math.abs(weight && transformationTwoWeight - weight) +
            Math.abs(height && transformationTwoHeight - height) +
            Math.abs(age && dobToAge(transformationTwo.dob) - age)

        switch (sort) {
            case "Relevance":
                if (!age && !weight && !height) return true
                else return transformationOneRelevanceScore - transformationTwoRelevanceScore
            case "Recent":
                return new Date(transformationTwo.lastUpdated) - new Date(transformationOne.lastUpdated)
            case "Specific":
                return transformationOne?.name?.localeCompare(transformationTwo?.name)
            case "Most Popular":
                return commentsIntoLikes
                    ? transformationTwo?.commentCount * commentsIntoLikes +
                          transformationTwo?.likeCount -
                          (transformationOne?.commentCount * commentsIntoLikes + transformationOne?.likeCount)
                    : true
            default:
                return true
        }
    })
}

export const sortAlphaNumeric = (a, b) => a.localeCompare(b, "en", { numeric: true })
