import axios from "../httpService"

export const getAllPublicTransformations = async () => {
    const { transformations } = await axios.get(`/transformations`, { noAuth: true })
    return transformations
}

export const getTransformation = async (transformationId) => {
    const { transformation } = await axios.get(`/transformation/${transformationId}`, { noAuth: true })
    return transformation
}

export const postTransformation = async (formData) => {
    // formData <object> : beforeImage, afterImage, transformation
    const { transformation } = await axios.post(`/transformation`, formData)
    return transformation
}

export const updateTransformationData = async (transformationId, updatedTransformationData) => {
    const { updatedTransformation } = await axios.post(`/transformation/${transformationId}`, updatedTransformationData)
    return updatedTransformation
}

export const updateTransformationImages = async (transformationId, progressPictureId, angle, file) => {
    // info : currently uploads only 1 'file' for a given 'angle' at a time
    const formData = new FormData()
    formData.append(angle, file)
    const { updatedTransformation } = await axios.post(
        `/transformation/images/${transformationId}/${progressPictureId}`,
        formData
    )
    return updatedTransformation
}

export const deleteTransformationImage = async (transformationId, progressPictureId, imageAngle, filename) => {
    const { updatedTransformation } = await axios.post(`/transformation/image/delete`, {
        transformationId,
        progressPictureId,
        imageAngle,
        filename,
    })
    return updatedTransformation
}

export const deleteTransformation = async (transformationId) => {
    const { success } = await axios.delete(`/transformation/${transformationId}`)
    return success
}

export const likeUnlikeTransformation = async (transformationId) => {
    const { likedTransformations, voteType } = await axios.get(`/transformation/vote/${transformationId}`)
    return { likedTransformations, voteType }
}

export const commentOnTransformation = async (commentBody, transformation, parent = false) => {
    const { transformationId, username: transformationOwner } = transformation
    let postBody = { commentBody, transformationOwner }
    if (parent) postBody = { ...postBody, parent }
    const { success } = await axios.post(`/transformation/${transformationId}/comment`, postBody)
    return success
}
