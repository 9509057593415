// Trainer/business specific services

import { sanitizeFilename } from "@progresspicture/shared/utils"
import { appConfig } from "config"
import { deleteFile, getCloudStorageUrl, getTrainerHeaderCloudPath, getTrainerHeaderFilename, uploadFile } from "utils"
import { db, remove, union } from "./init"

export const updateTrainerHeaderImage = async (username, file, existingFilename = null) => {
    // create filename and cloudPath for the given file
    const safeFilename = sanitizeFilename(`${new Date().toISOString()}_${file.name}`)
    const filename = getTrainerHeaderFilename(safeFilename)
    const cloudPath = getTrainerHeaderCloudPath(username, filename)
    // create image data for the db
    let imageData = {}
    imageData["filename"] = filename
    imageData["url"] = getCloudStorageUrl(cloudPath)
    appConfig.imageThumbnailSizes["headerImage"].forEach((size) => {
        const thumbFilename = getTrainerHeaderFilename(filename, size)
        const thumbFilePath = getTrainerHeaderCloudPath(username, thumbFilename)
        imageData[`thumb${size}`] = getCloudStorageUrl(thumbFilePath)
    })
    // upload the new file
    const { downloadUrl, fileRef } = await uploadFile({ file, cloudPath, metadata: { contentType: file.type } })
    // if updating image, delete existing image
    if (existingFilename) {
        console.log("deleting existing image...")
        const cloudPathToDelete = getTrainerHeaderCloudPath(username, existingFilename)
        deleteFile(cloudPathToDelete).catch(console.error)
    }

    try {
        // set new headerImages data
        const trainerRef = db.doc(`/users/${username}`)
        let { headerImages = [] } = (await trainerRef.get()).data()
        const existingImageIndex = headerImages.findIndex((image) => image.filename === existingFilename)
        existingImageIndex >= 0 ? (headerImages[existingImageIndex] = { ...imageData }) : headerImages.push(imageData)
        await trainerRef.update({ headerImages })
        return headerImages
    } catch (error) {
        await fileRef.delete()
        throw error
    }
}

export const deleteTrainerHeaderImage = async (username, filename) => {
    // delete image from storage
    const cloudPathToDelete = getTrainerHeaderCloudPath(username, filename)
    deleteFile(cloudPathToDelete).catch(console.error)
    // delete image from db
    const trainerRef = db.doc(`/users/${username}`)
    let { headerImages = [] } = (await trainerRef.get()).data()
    const existingImageIndex = headerImages.findIndex((image) => image.filename === filename)
    if (existingImageIndex >= 0) headerImages.splice(existingImageIndex, 1)
    await trainerRef.update({ headerImages })
    return headerImages
}

export const updateTrainerData = async (username, dataToUpdate) => {
    const userRef = db.doc(`/users/${username}`)
    await userRef.update({ ...dataToUpdate })
    return { ...dataToUpdate }
}

export const addNewServiceHighlight = async (username, serviceHighlight) => {
    const userRef = db.doc(`/users/${username}`)
    await userRef.update({ serviceHighlights: union(serviceHighlight) })
    return { ...serviceHighlight }
}

export const deleteServiceHighlight = async (username, index) => {
    const userRef = db.doc(`/users/${username}`)
    let { serviceHighlights } = (await userRef.get()).data()
    if (serviceHighlights.length >= index) {
        serviceHighlights.splice(index, 1)
        await userRef.update({ serviceHighlights })
        return serviceHighlights
    } else throw new Error("Invalid index to delete serviceHighlights!")
}

export const deleteTrainerService = async (serviceId, trainerUsername) => {
    const serviceRef = db.collection("services").doc(serviceId)
    const trainerRef = db.collection("users").doc(trainerUsername)
    await serviceRef.delete()
    await trainerRef.update({
        services: remove(serviceId),
    })
}

export const deleteTrainerClientTransformation = async (clientId) => {
    const clientTransformationRef = db.collection("clientsTransformation").doc(clientId)
    const clientTransformationData = (await clientTransformationRef.get()).data()
    const userRef = db.doc(`/users/${clientTransformationData.trainer}`)
    await userRef.update({ clients: remove(clientId) })
    if (clientTransformationData?.service) {
        const ServiceRef = db.doc(`/services/${clientTransformationData.service}`)
        ServiceRef.update({ clientIds: remove(clientId) })
    }
    await clientTransformationRef.delete()
}
