// TODO: Optimize firebase usage. And shift to new version

import firebase from "firebase/app"
// eslint-disable-next-line import/order
import "firebase/analytics"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import { firebaseConfig, isEnvDev } from "config"

if (typeof window !== "undefined" && !firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
    // To enable analytics
    if ("measurementId" in firebaseConfig) firebase.analytics()
}
const firebaseAuth = firebase.auth()
if (isEnvDev) firebaseAuth.useEmulator("http://localhost:9099")
const db = firebase.firestore()
if (isEnvDev) {
    db.useEmulator("localhost", 8000)
    db.settings({
        experimentalForceLongPolling: true,
        merge: true,
    })
}
const storage = firebase.storage()
if (isEnvDev) storage.useEmulator("localhost", 9199)

// methods for array ops in firestore
const union = firebase.firestore.FieldValue.arrayUnion
const remove = firebase.firestore.FieldValue.arrayRemove
const increment = firebase.firestore.FieldValue.increment(1)
const decrement = firebase.firestore.FieldValue.increment(-1)

export { firebase, firebaseAuth, db, storage, union, remove, increment, decrement }
