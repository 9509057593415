import React from "react"
import { renderToString } from "react-dom/server"
import ClientVerificationInvite from "pages/admin/email/templates/client_verification_invite"
import ClientEnquiry from "pages/admin/email/templates/clientEnquiry"
import PasswordResetEmail from "pages/admin/email/templates/password_reset"
import VerificationEmail from "pages/admin/email/templates/verification"
import WelcomeEmail from "pages/admin/email/templates/welcome"
import axios from "services/httpService"

// sends the plain html content of the email templates for the server to update the current ones,
// this is meant to be done via the '/admin/email/templates' route (requires 'admin' role and login)
export async function update_template(email_type) {
    const jsxToString = (email_component) => {
        return renderToString(email_component)
    }

    const emailTemplates = {
        welcome: <WelcomeEmail />,
        verification: <VerificationEmail />,
        password_reset: <PasswordResetEmail />,
        client_verification_invite: <ClientVerificationInvite />,
        client_enquiry: <ClientEnquiry />,
    }

    let email_content = jsxToString(emailTemplates[email_type])

    const { success } = await axios.post(`/admin/update_template`, {
        emailType: email_type,
        emailContent: email_content,
    })
    return success
}

// sends the json of the users to the server to register new users,
// this is meant to be done via the '/admin/ops' route (requires 'admin' role and login)
export async function user_signup(data) {
    const { success, error } = await axios.post(`/admin/bulk_user_signup`, data)
    return { success, error }
}

// sends the json of the transformation of existing user to the server to create user's transformation,
// this is meant to be done via the '/admin/ops' route (requires 'admin' role and login)
export async function checkUserTransformationData(data) {
    const { success } = await axios.post(`/admin/user_transformation`, data)
    return success
}
