const initialState = {
    loading: false,
    redirect: "",
    authAction: "",
    currentUser: {},
    googleUser: {},
    show_modal: false,
    current_modal: "",
    modalContext: {},
}

export default initialState
