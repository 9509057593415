import React from "react"
import { useSelector } from "react-redux"
import { Modal } from "antd"
import Login from "components/auth/login"
import { selectCurrentAuthModal, selectShowAuthModal } from "reduxStore/selectors"
import { closeModal, showModal } from "utils"

const subTitle = `This action requires you to be logged in. Please login to complete this action or,
                if you don't already have an account, signup in just a few seconds!`

const LoginModal = ({ modalName, cancelCallback, showPrivilegedActionWarning }) => {
    const current_modal = useSelector(selectCurrentAuthModal)
    const show_modal = useSelector(selectShowAuthModal)

    return (
        <Modal
            title={"Login"}
            centered
            visible={show_modal && current_modal === modalName}
            width={400}
            closable={true}
            keyboard={true}
            destroyOnClose={true}
            onCancel={() => {
                closeModal("auth")
                cancelCallback()
            }}
            footer={""}
        >
            <Login
                subTitle={showPrivilegedActionWarning ? subTitle : ""}
                changePrompt={() => showModal("auth", "signup")}
            />
        </Modal>
    )
}

export default LoginModal
