import axios from "../httpService"

export const addUpdateTrainerClientTransformation = async (clientTransformationData, transformationId = null) => {
    // updates if transformationId is provided, adds otherwise
    const formData = new FormData()
    formData.append("clientTransformationData", JSON.stringify(clientTransformationData))
    const reqUrl = transformationId ? `/user/trainer/clients/${transformationId}` : `/user/trainer/clients`
    const { trainerClients } = await axios.post(reqUrl, { clientTransformationData })
    return trainerClients
}

export const addUpdateTrainerService = async (serviceData, image, serviceId = null) => {
    // updates if serviceId is provided, adds otherwise
    const formData = new FormData()
    formData.append("serviceData", JSON.stringify(serviceData))
    if (image) formData.append("serviceImage", image)
    const reqUrl = serviceId ? `/user/trainer/service/${serviceId}` : `/user/trainer/service`
    const { trainerServices } = await axios.post(reqUrl, formData)
    return trainerServices
}

export const deleteTrainerTransformationImage = async (transformationId, progressPictureId, imageAngle, filename) => {
    const { updatedTransformation } = await axios.post(`/trainer/transformation/image/delete`, {
        transformationId,
        progressPictureId,
        imageAngle,
        filename,
    })
    return updatedTransformation
}

export const sendVerificationInviteEmail = async (clientEmail, clientTransformationId, clientName) => {
    const { success } = await axios.post(`/trainer/verification/invite`, {
        email: clientEmail,
        clientTransformationId,
        clientName,
    })
    return { success }
}
