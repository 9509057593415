import React, { lazy, Suspense } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { ConnectedRouter } from "connected-react-router"
import Layout from "components/auth/layout-loader"
import { isEnvDevOrStaging } from "config"
import ErrorBoundary from "errorBoundary"

// Defines the route -> Component mapping
const routes = [
    // Public Pages
    {
        path: "/",
        Component: lazy(() => import("pages/public/Landing")),
        exact: true,
    },
    {
        path: "/home",
        Component: lazy(() => import("pages/public/Landing")),
        exact: true,
    },
    {
        path: "/transformations",
        Component: lazy(() => import("pages/public/Transformations")),
        exact: true,
    },
    {
        path: "/transformation/:transformationId",
        Component: lazy(() => import("pages/public/Transformation")),
        exact: true,
    },
    {
        path: "/profile/user/:transformationUsername",
        Component: lazy(() => import("pages/public/Transformation")),
        exact: false,
    },
    {
        path: "/coaches",
        Component: lazy(() => import("pages/public/Coaches")),
        exact: true,
    },
    {
        path: "/coach/:username",
        Component: lazy(() => import("pages/public/Trainer")),
        exact: true,
    },
    {
        path: "/business",
        Component: lazy(() => import("pages/public/Business")),
        exact: true,
    },
    {
        path: "/legal/terms-of-service",
        Component: lazy(() => import("pages/public/Terms")),
        exact: true,
    },
    {
        path: "/legal/privacy-policy",
        Component: lazy(() => import("pages/public/Privacy")),
        exact: true,
    },
    {
        path: "/contact",
        Component: lazy(() => import("pages/public/Contact")),
        exact: true,
    },
    {
        path: "/faqs",
        Component: lazy(() => import("pages/public/FAQs")),
        exact: true,
    },

    // Protected Pages
    {
        path: "/user/submit",
        Component: lazy(() => import("pages/protected/submission-flow")),
        exact: true,
        hideLayout: true,
    },
    {
        path: "/submit/client",
        Component: lazy(() => import("pages/protected/submit-client-transformation")),
        exact: true,
        hideLayout: true,
    },
    {
        path: "/user/dashboard",
        Component: lazy(() => import("pages/protected/profile")),
        exact: true,
    },
    {
        path: "/widget/:username",
        Component: lazy(() => import("pages/public/Widget")),
        exact: true,
        hideLayout: true,
    },

    // Email action handler
    {
        path: "/auth",
        Component: lazy(() => import("components/auth/action-handler")),
        exact: false,
    },

    // Error Pages
    {
        path: "/error/404",
        Component: lazy(() => import("pages/error/404")),
        exact: true,
    },
    {
        path: "/error/500",
        Component: lazy(() => import("pages/error/500")),
        exact: true,
    },

    // Admin pages
    {
        path: "/admin/email",
        Component: lazy(() => import("pages/admin/email")),
        exact: true,
    },
    {
        path: "/admin/ops",
        Component: lazy(() => import("pages/admin/operations")),
        exact: true,
    },

    // Under development pages (only available in development & staging environments)
    ...(isEnvDevOrStaging
        ? [
              // all non-prod pages should be added here
              {
                  path: "/widget-test",
                  Component: lazy(() => import("pages/public/WidgetTest")),
                  exact: true,
                  hideLayout: true,
              },
          ]
        : []),
]

const Router = (props) => {
    const { history } = props
    return (
        <ConnectedRouter history={history}>
            <Switch>
                {/* routing logic for the rest of the routes. */}
                {/* mapping the defined routes to the Route component. */}
                {routes.map(({ path, Component, exact, hideLayout }) => (
                    <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={(props) => {
                            return hideLayout !== undefined && hideLayout ? (
                                <Suspense fallback={null}>
                                    <ErrorBoundary>
                                        <Component {...props} />
                                    </ErrorBoundary>
                                </Suspense>
                            ) : (
                                <Layout>
                                    <Suspense fallback={null}>
                                        <ErrorBoundary>
                                            <Component {...props} />
                                        </ErrorBoundary>
                                    </Suspense>
                                </Layout>
                            )
                        }}
                    />
                ))}
                {/* if page/route not found */}
                <Redirect to="/error/404" />
            </Switch>
        </ConnectedRouter>
    )
}

export default Router
