import { db, decrement, increment } from "./init"

export const getTransformationComments = async (transformationId) => {
    const transformationComments = await db
        .collection("comments")
        .where("transformationId", "==", transformationId)
        .orderBy("createdOn")
        .get()
    let comments = transformationComments.docs.map((doc) => ({ ...doc.data(), commentId: doc.id }))
    let commentCount = comments.length
    return { comments, commentCount }
}

export const deleteTransformationComment = async (transformationId, commentId) => {
    // check if comment has children
    const tree = await db
        .collection("comments")
        .where("parent", ">=", commentId)
        .where("parent", "<=", `${commentId}~`)
        .get()
    const children = tree.docs.map((doc) => doc.data())
    const nChildren = children.length
    const commentRef = db.doc(`/comments/${commentId}`)
    // if children exist
    if (nChildren > 0) {
        await commentRef.update({
            status: "deleted",
            body: "",
        })
    }
    // if no children
    else {
        const transformationRef = db.doc(`/transformations/${transformationId}`)
        const batch = db.batch()
        batch.delete(commentRef)
        batch.update(transformationRef, { commentCount: decrement })
        return await batch.commit()
    }
}

export const editTransformationComment = async (commentBody, commentId) => {
    const commentRef = db.doc(`/comments/${commentId}`)
    await commentRef.update({
        lastUpdated: new Date().toISOString(),
        body: commentBody,
        status: "edited",
    })
}

export const voteComment = async (username, comment, voteType) => {
    // voteType = "up" or "down"

    // throw error if the comment being voted on is deleted
    if (comment.status === "deleted") throw new Error("Can't vote on deleted comment!")
    const { transformationId, transformationOwner, commentId, username: commentAuthor } = comment
    const commentRef = db.doc(`comments/${commentId}`)
    const voteRef = db.collection("votes").doc(`${username}_${commentId}`)
    const existingVoteData = await voteRef.get()
    const batch = db.batch()
    let actionPerformed, valueChange
    // either create a new vote doc or delete the existing one (if already liked)
    if (!existingVoteData.exists) {
        const voteData = {
            createdOn: new Date().toISOString(),
            transformationId,
            transformationOwner,
            commentId,
            commentAuthor,
            voter: username,
            vote: voteType === "up" ? 1 : -1,
        }
        batch.set(voteRef, voteData)
        batch.update(commentRef, { voteCount: voteType === "up" ? increment : decrement })
        actionPerformed = voteType
        valueChange = voteType === "up" ? +1 : -1
    } else {
        const existingVoteValue = existingVoteData.data()["vote"]
        valueChange = existingVoteValue > 0 ? -1 : +1
        actionPerformed = null
        batch.delete(voteRef)
        batch.update(commentRef, { voteCount: existingVoteValue > 0 ? decrement : increment })
    }
    await batch.commit()
    return { actionPerformed, valueChange }
}

export const getVotedTransformationComments = async (username, transformationId) => {
    let votedTransformationComments = []
    let votes = await db
        .collection("votes")
        .where("transformationId", "==", transformationId)
        .where("voter", "==", username)
        .get()
    votes.forEach((doc) => {
        let { commentId, vote } = doc.data()
        votedTransformationComments.push({ commentId, vote })
    })
    return votedTransformationComments
}
