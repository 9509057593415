import React from "react"

const ClientVerificationInvite = () => {
    return (
        <>
            {/*Main Container*/}
            <div
                style={{
                    margin: "0",
                    padding: "0",
                    background: "#ECEDFE",
                }}
            >
                {/*Email Container*/}
                <div
                    style={{
                        // background: '#fff',
                        margin: "0 auto",
                        padding: "50px 0",
                        color: "#514d6a",
                        borderRadius: "5px",
                        maxWidth: "550px",
                        fontSize: "14px",
                        textAlign: "center",
                    }}
                >
                    {/*Company Name / Logo*/}
                    <div
                        style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                            padding: "10px",
                            marginBottom: "20px",
                        }}
                    >
                        <a href={"_DOMAIN_NAME_"}>
                            <img
                                src={
                                    "https://firebasestorage.googleapis.com/v0/b/progresspicture.appspot.com/o/progress_picture_logo_no_bg_cropped.png?alt=media"
                                }
                                alt="_COMPANY_NAME_"
                                style={{ width: "240px", maxWidth: "100%" }}
                            />
                        </a>
                    </div>

                    {/*CARD*/}
                    <div
                        style={{
                            padding: "40px 40px 20px 40px",
                            background: "#fff",
                            borderRadius: "20px",
                            borderStyle: "none",
                            borderWidth: "1px",
                            justifyContent: "center",
                        }}
                    >
                        {/*Subject*/}
                        <div>
                            <h4
                                style={{
                                    marginBottom: "30px",
                                    color: "#0d0c46",
                                    fontWeight: "600",
                                    fontSize: "24px",
                                }}
                            >
                                Verify Transformation
                            </h4>
                        </div>

                        {/*Email Body*/}
                        <div style={{ margin: "30px 0", fontSize: "18px", fontWeight: 400 }}>
                            <p>Hey!</p>
                            <p>
                                _TRAINER_NAME_ has requested to verify your transformation, please verify your
                                transformation below.
                                <br />
                                <br />
                                If you think this email was not meant for you, please ignore this email or contact us at{" "}
                                <a href={"mailto:_SUPPORT_EMAIL_"} target="_blank">
                                    _SUPPORT_EMAIL_
                                </a>
                                .
                                <br />
                            </p>

                            <div style={{ textAlign: "center" }}>
                                <a
                                    style={{
                                        display: "inline-block",
                                        padding: "11px 30px 10px",
                                        margin: "20px 0px 10px",
                                        fontSize: "15px",
                                        color: "#ffffff",
                                        background: "#0d0c46",
                                        borderRadius: "10px",
                                        textDecoration: "none",
                                        fontWeight: "bold",
                                    }}
                                    href={"_CLIENT_VERIFICATION_INVITE_LINK_"}
                                >
                                    Verify Transformation
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClientVerificationInvite
